<template>
  <div class="login-container">
    <div class="divider-container">
      <div class="divider-line"></div>
      <span class="divider-text">ou</span>
      <div class="divider-line"></div>
    </div>
    <div class="buttons-container">

      <div class="google-btn" @click="handleGoogleLogin">
        <div class="google-icon-wrapper">
          <svg class="google-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path fill="#EA4335"
              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" />
            <path fill="#4285F4"
              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" />
            <path fill="#FBBC05"
              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" />
            <path fill="#34A853"
              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" />
            <path fill="none" d="M0 0h48v48H0z" />
          </svg>
        </div>
        <span class="btn-text">{{ googleButtonText }}</span>
      </div>

      <div class="microsoft-btn" @click="handleMicrosoftLogin">
        <div class="microsoft-icon-wrapper">
          <div class="microsoft-icon">
            <div class="microsoft-square1"></div>
            <div class="microsoft-square2"></div>
            <div class="microsoft-square3"></div>
            <div class="microsoft-square4"></div>
          </div>
        </div>
        <span class="btn-text">{{ microsoftButtonText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginButtons',
  props: {
    title: {
      type: String,
      default: 'Faça login com:'
    },
    googleButtonText: {
      type: String,
      default: 'Entrar com Google'
    },
    microsoftButtonText: {
      type: String,
      default: 'Entrar com Microsoft'
    }
  },
  methods: {
    handleGoogleLogin() {
      this.$emit('google-login');
    },
    handleMicrosoftLogin() {
      this.$emit('microsoft-login');
    }
  }
}
</script>

<style scoped>
.login-container {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 20px
}

.buttons-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Estilo do botão do Google */
.google-btn {
  width: 240px;
  height: 42px;
  background-color: #F4FCFD;
  border-radius: 2px;
  border: 1px solid #898989;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s ease;
}

.google-icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 2px 0 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F4FCFD;
}

.google-icon {
  width: 18px;
  height: 18px;
}

.btn-text {
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: 'Roboto', sans-serif;
  color: #575757;
  padding-left: 24px;
}

.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}

/* Estilo do botão da Microsoft */
.microsoft-btn {
  width: 240px;
  height: 42px;
  background-color: #F4FCFD;
  border-radius: 2px;
  border: 1px solid #898989;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s ease;
}

.microsoft-icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 2px 0 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.microsoft-icon {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1px;
  width: 15px;
  height: 15px;
}

.microsoft-square1 {
  background-color: #f25022;
}

.microsoft-square2 {
  background-color: #7fba00;
}

.microsoft-square3 {
  background-color: #00a4ef;
}

.microsoft-square4 {
  background-color: #ffb900;
}

.microsoft-btn .btn-text {
  color: #575757;
}

.microsoft-btn:hover {
  box-shadow: 0 0 6px #00a4ef;
}

.divider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  width: 100%;
}

.divider-line {
  flex-grow: 1;
  height: 1px;
  min-width: 100px;
  background-color: #d1d5db;
  /* Tailwind's gray-300 */
}

.divider-text {
  margin: 0 8px;
  color: #6b7280;
  /* Tailwind's gray-500 */
}

h1 {
  margin-bottom: 30px;
  color: #333;
}
</style>