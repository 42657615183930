import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    OAuthProvider
} from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const signInWithProvider = async provider => {
    try {
        switch (provider) {
            case 'google':
                provider = new GoogleAuthProvider();
                break;
            case 'microsoft':
                provider = new OAuthProvider('microsoft.com');
                break;
            default:
                break;
        }
        const result = await signInWithPopup(auth, provider);
        const token = await result.user.getIdToken();
        return token;
    } catch (error) {
        console.error('Error signing in:', error);
    }
};
