<template>
    <div class="about">
        <v-card class="ma-3 pa-3">
            <v-row>
                <v-col sm="12" md="12">
                    <v-parallax
                        dark
                        src="@/assets/background-azul-escuro-reduzido-2.jpg"
                    >
                        <v-row align="center" justify="center">
                            <v-col class="text-center" cols="12">
                                <h1 class="display-1 font-weight-thin mb-4">
                                    Manual do Usuário e de Integração 2.0
                                </h1>
                                <h3 class="heading">Recuperi | 2023</h3>
                            </v-col>
                        </v-row>
                    </v-parallax>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col sm="12" md="12">
                    <v-card-title class="grey--text font-weight-black display-1"
                        >1. Início</v-card-title
                    >
                    <v-card-text>
                        Obrigado por escolher a RECUPERI, sua parceira na
                        cobrança de valores por todo o Brasil! O presente Manual
                        do Usuário e de Integração (“Manual”) foi criado para
                        facilitar o uso e acelerar os seus processos de
                        integração com nossa Plataforma. Nosso Manual também
                        possui explicações sobre as diversas as informações
                        sobre os títulos necessárias para protesto, de acordo
                        com os requisitos exigidos pela legislação aplicável e
                        práticas adotadas pelos tabeliães de protesto. Dúvidas,
                        orientações e solicitações específicas podem ser
                        direcionadas para suporte@recuperi.com.br
                    </v-card-text>
                    <v-card-title class="grey--text font-weight-black display-1"
                        >2. Nossa Plataforma(painel)</v-card-title
                    >
                    <v-card-text>
                        Nosso Painel pode ser acessado por meio do link
                        https://painel.recuperi.com.br/ ou em nosso site
                        www.recuperi.com.br. As informações de acesso são
                        enviadas individualmente e por solicitação do
                        Apresentante ao nosso suporte, de modo a garantir a
                        segurança dos usuários – e poderão ser solicitadas por
                        meio do contato suporte@recuperi.com.br.
                    </v-card-text>
                    <v-row>
                        <v-col md="4">
                            <v-card-title
                                class="grey--text font-weight-black title"
                                >2.1 Funcionalidades</v-card-title
                            >
                            <v-card-text>
                                Ao acessar nossa Plataforma, você terá acesso
                                imediato ao seu Dashboard, que contém uma
                                apresentação geral dos status dos seus títulos
                                enviados.
                            </v-card-text>
                            <v-card-text>
                                Em nosso Dashboard também é possível ter acesso
                                às diversas funcionalidades gerenciais da
                                Plataforma, inclusive consulta de títulos por
                                status, quantidade e valores protestados,
                                evolução dos procedimentos de protesto,
                                pagamento de títulos e histórico de importações.
                            </v-card-text>
                        </v-col>
                        <v-col md="8">
                            <v-img
                                class="ma-3"
                                max-height="700"
                                max-width="700"
                                src="@/assets/manual/screen-manual-1.png"
                            ></v-img>
                        </v-col>
                    </v-row>
                    <v-row class="ma-3 pa-3">
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    large
                                    dense
                                    color="primary white--text"
                                    width="105%"
                                    @click="$vuetify.goTo($refs.uploadTitulos)"
                                >
                                    <v-icon class="mr-2"> cloud_upload </v-icon>
                                    Upload de Títulos</v-btn
                                >
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    large
                                    dense
                                    color="primary white--text"
                                    width="105%"
                                    @click="
                                        $vuetify.goTo($refs.consultaTitulos)
                                    "
                                >
                                    <v-icon class="mr-2"> search </v-icon>
                                    Consulta de Títulos</v-btn
                                >
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    large
                                    dense
                                    color="primary white--text"
                                    width="105%"
                                    @click="$vuetify.goTo($refs.ofertas)"
                                >
                                    <v-icon class="mr-2"> local_offer </v-icon>
                                    Ofertas</v-btn
                                >
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    large
                                    dense
                                    color="primary white--text"
                                    width="105%"
                                    @click="$vuetify.goTo($refs.enviarSms)"
                                >
                                    <v-icon class="mr-2"> message </v-icon>
                                    Enviar SMS</v-btn
                                >
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    large
                                    dense
                                    color="primary white--text"
                                    width="105%"
                                >
                                    <v-icon class="mr-2"> email </v-icon>
                                    Enviar E-mails</v-btn
                                >
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    large
                                    dense
                                    color="primary white--text"
                                    width="105%"
                                >
                                    <v-icon class="mr-2"> assignment </v-icon>
                                    Relatórios</v-btn
                                >
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    large
                                    dense
                                    color="primary white--text"
                                    width="105%"
                                >
                                    <v-icon class="mr-2"> map </v-icon>
                                    Mapa de Títulos</v-btn
                                >
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    large
                                    dense
                                    color="primary white--text"
                                    width="105%"
                                >
                                    <v-icon class="mr-2"> search </v-icon>
                                    Usuários</v-btn
                                >
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    large
                                    dense
                                    color="primary white--text"
                                    width="105%"
                                    @click="$vuetify.goTo($refs.alteracoesLote)"
                                >
                                    <v-icon class="mr-2"> mdi-sync </v-icon>
                                    Alterações Lote</v-btn
                                >
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-card-actions ref="uploadTitulos">
                        <v-card-title class="grey--text font-weight-black title"
                            >2.1.1 Tela de Upload de Títulos: Visão
                            geral</v-card-title
                        >
                    </v-card-actions>
                    <v-card-text>
                        A funcionalidade upload de títulos permite o envio de
                        títulos para protesto para cartórios de protesto em
                        todos os Estados do Brasil. Ela é disponibilizada logo
                        após a homologação de um novo Apresentante perante os
                        cartórios de protesto e envio das informações de acesso.
                        Para tanto, o usuário faz a importação de planilha com
                        os dados dos títulos completando o respectivo template
                        padrão, cumprindo nossas instruções para realizar o seu
                        upload na www.recuperi.com.br 5 Plataforma. Maiores
                        informações sobre as validações das informações
                        encontram-se ao final do Manual, no capítulo Validações
                        Básicas dos Títulos. Orientações adicionais também podem
                        ser encontradas no Dicionário de Campos e no Glossário
                        ao final do Manual.
                    </v-card-text>
                    <v-row>
                        <v-col md="4">
                            <v-card-title
                                class="grey--text font-weight-black title"
                                >2.1.2 Upload de Títulos:
                                procedimentos</v-card-title
                            >
                            <v-card-text>
                                Nossa Plataforma permite grande flexibilização
                                em seu uso e adequação às necessidades e
                                planejamento dos seus usuários. Conforme
                                indicado anteriormente, uma das opções de envio
                                de títulos para protesto é por meio da
                                importação de planilhas Excel – opção que
                                permite ao usuário manualmente importar suas
                                bases na Plataforma.
                            </v-card-text>
                            <v-card-text>
                                A planilha a ser importada deve ser preenchida a
                                partir do template padrão em Excel, com download
                                através do botão disponível na funcionalidade
                                Upload de Títulos.
                            </v-card-text>
                        </v-col>
                        <v-col md="8">
                            <v-img
                                lazy-src="@/assets/manual/screen-manual-2.png"
                                max-height="700"
                                max-width="700"
                                class="ma-3"
                                src="@/assets/manual/screen-manual-2.png"
                            ></v-img>
                        </v-col>
                    </v-row>
                    <v-card-title class="grey--text font-weight-black title"
                        >Opções da Tela:</v-card-title
                    >
                    <v-row>
                        <v-col cols="12" xs="12" md="6">
                            <v-card-title
                                class="grey--text font-weight-black title"
                                >Enviar</v-card-title
                            >
                            <v-card-text>
                                Após efetuar esse comando, a planilha Excel será
                                validada pelos filtros de formatação da
                                Plataforma:
                            </v-card-text>
                            <v-card-text>
                                Caso existam irregularidades, elas serão
                                indicadas na coluna “Títulos Irregulares”. Com
                                isso, após seu apontamento, o usuário poderá
                                analisar estas possíveis irregularidades,pois se
                                encontram discriminadas no Anexo
                                “Irregularidades” ao final do Manual. Após
                                superar as irregularidades, o usuário poderá
                                reenviar, fazendo o upload, de arquivo com os
                                dados deste título.
                            </v-card-text>
                            <v-card-text>
                                Caso existam título em revisão, eles aparecerão
                                destacados em vermelho na respectivacoluna
                                “Títulos em revisão”. Títulos em revisão possuem
                                o nome do devedor ou oendereço com mais de 45
                                caracteres, mesmo após o encurtamento realizado
                                pelaPlataforma e precisam ser manualmente
                                corrigidas por meio da opção CORREÇÃO.
                            </v-card-text>
                        </v-col>
                        <v-col cols="12" xs="12" md="3">
                            <v-card-title
                                class="grey--text font-weight-black title"
                                >Resultado</v-card-title
                            >
                            <v-card-text>
                                Download da planilha Excel enviada, com adição
                                de uma coluna indicando possíveis
                                irregularidades.
                            </v-card-text>
                        </v-col>
                        <v-col cols="12" xs="12" md="3">
                            <v-card-title
                                class="grey--text font-weight-black title"
                                >Confirmar</v-card-title
                            >
                            <v-card-text>
                                Todos itens sem irregularidades ou em revisão
                                serão enviados para protesto através da nossa
                                Plataforma.
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" md="4">
                            <v-card-title
                                class="grey--text font-weight-black title"
                                >Correção</v-card-title
                            >
                            <v-card-text>
                                a opção será disponibilizada para que sejam
                                realizados ajustes nas informações do nome do
                                devedor ou do endereço para cumprimento do
                                limite máximo de 45 caracteres para que o envio
                                para protesto seja liberado.
                            </v-card-text>
                            <v-card-text>
                                Para cada título em revisão, deve-se acessar a
                                opção DETALHES (que pode ser acessada pelo ícone
                                ) para proceder com sua correção.
                            </v-card-text>
                        </v-col>
                        <v-col md="8">
                            <v-img
                                lazy-src="@/assets/manual/screen-manual-3.png"
                                max-height="700"
                                max-width="700"
                                class="ma-3"
                                src="@/assets/manual/screen-manual-3.png"
                            ></v-img>
                        </v-col>
                    </v-row>
                    <v-card-actions ref="consultaTitulos">
                        <v-card-title class="grey--text font-weight-black title"
                            >2.1.3 Consulta de Títulos</v-card-title
                        >
                    </v-card-actions>
                    <v-card-text>
                        A funcionalidade de Consulta de Títulos permite rápido
                        acesso a informações e status de títulos individualmente
                        ou em grupo, filtrados conforme a necessidade de
                        acompanhamento. Ela também permite acesso à opção
                        DETALHES (que pode ser acessada pelo ícone disponível
                        para cada título consultado), onde o usuário poderá
                        interagir com a Plataforma para solicitar cancelamento,
                        anuência e desistência de protestos entre outras ações.
                    </v-card-text>
                    <v-row>
                        <v-col md="8">
                            <v-img
                                lazy-src="@/assets/manual/screen-manual-4.png"
                                max-height="700"
                                class="ma-3"
                                max-width="700"
                                src="@/assets/manual/screen-manual-4.png"
                            ></v-img>
                        </v-col>
                        <v-col md="4">
                            <v-card-title
                                class="grey--text font-weight-black title"
                                >Tela Consulta de Títulos</v-card-title
                            >
                            <v-card-text>
                                Utilize a tela Consulta de Títulos para
                                encontrar qualquer título no sistema, podendo
                                utilizar também filtros para localizar títulos
                                específicos ou revisa-los.
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="4">
                            <v-card-title
                                class="grey--text font-weight-black title"
                                >Filtrar</v-card-title
                            >
                            <v-card-text>
                                Após o preenchimento dos campos de pesquisa de
                                acordo com os filtros desejados, a opção filtrar
                                permite a listagem com os títulos identificados
                                pela Plataforma. Como exemplo, segue resultado
                                de pesquisa de títulos pelo status “protesto
                                solicitado”.
                            </v-card-text>
                            <v-card-title
                                class="grey--text font-weight-black title"
                                >Somente em Revisão</v-card-title
                            >
                            <v-card-text>
                                Filtro relevante, pois por ele é possível
                                filtrar todos títulos que estão pendentes de
                                revisão de todas planilhas importadas até a data
                                corrente. É recomendável verificar se existem
                                títulos pendentes de revisão, pois sem correção
                                da informação pendente (como dados de endereço
                                muito extensos) não será possível enviá-los para
                                protesto.
                            </v-card-text>
                        </v-col>
                        <v-col md="8">
                            <v-img
                                lazy-src="@/assets/manual/screen-manual-5.png"
                                max-height="700"
                                class="ma-3"
                                max-width="700"
                                src="@/assets/manual/screen-manual-5.png"
                            ></v-img>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="8">
                            <v-img
                                lazy-src="@/assets/manual/screen-manual-6.png"
                                class="ma-3"
                                max-height="700"
                                max-width="700"
                                src="@/assets/manual/screen-manual-6.png"
                            ></v-img>
                        </v-col>
                        <v-col md="4">
                            <v-card-title
                                class="grey--text font-weight-black title"
                                >Detalhes do Título</v-card-title
                            >
                            <v-card-text>
                                A opção DETALHES pode ser acessada pelo ícone
                                disponível para cada título consultado. O
                                usuário será direcionado para tela que contém
                                todos detalhes do título selecionado.
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-card-title class="grey--text font-weight-black title"
                        >Detalhes do Título</v-card-title
                    >
                    <v-card-text>
                        A funcionalidade de Consulta de Títulos permite rápido
                        acesso a informações e status de títulos individualmente
                        ou em grupo, filtrados conforme a necessidade de
                        acompanhamento. Ela também permite acesso à opção
                        DETALHES (que pode ser acessada pelo ícone disponível
                        para cada título consultado), onde o usuário poderá
                        interagir com a Plataforma para solicitar cancelamento,
                        anuência e desistência de protestos entre outras ações.
                    </v-card-text>
                    <v-row>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="teal white--text"
                                    width="100%"
                                >
                                    Correção
                                    <v-icon class="ml-2">build</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Conforme explicado anteriormente, será
                                    disponibilizada para que seja realizada a
                                    correção nas informações sobre o título em
                                    revisão.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    class=""
                                    dense
                                    color="success darken-1"
                                    width="100%"
                                >
                                    Anuência
                                    <v-icon class="ml-2">check</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Será enviada uma solicitação de anuência
                                    para o título corrente na tela de DETALHES.
                                    Ação permitida apenas para títulos que estão
                                    com status de “Protestado”.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    class=""
                                    dense
                                    color="error"
                                    width="100%"
                                >
                                    Cancelamento
                                    <v-icon class="ml-2">cancel</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Será enviada uma solicitação de cancelamento
                                    para o título corrente na tela de DETALHES.
                                    Ação permitida apenas para títulos que estão
                                    com status de “Protestado”.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    class="blue white--text"
                                    width="100%"
                                    dense
                                    color="blue"
                                >
                                    Desistência
                                    <v-icon class="ml-2">stop</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Será enviada uma solicitação de desistência
                                    para o título corrente na tela de DETALHES.
                                    Ação permitida apenas para títulos que estão
                                    com status anteriores a “Protestado”.
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        Dentro da Consulta de Títulos também é possível a
                        extração de informações e atualizações da evolução de
                        status sobre todos os títulos enviados (ou conforme
                        filtros selecionados), por meio de Relatórios
                        simplificados ou detalhados:
                    </v-card-text>
                    <v-row>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    class="blue white--text"
                                    width="100%"
                                    dense
                                    color="primary"
                                >
                                    PDF
                                    <v-icon class="ml-2">cloud_download</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Relatório simplificado, com resumo das
                                    principais informações dos títulos
                                    filtrados.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    class="blue white--text"
                                    width="100%"
                                    dense
                                    color="primary"
                                >
                                    EXCEL
                                    <v-icon class="ml-2">cloud_download</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Relatório completo, com todas as informações
                                    dos títulos filtrados.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    class="blue white--text"
                                    width="100%"
                                    dense
                                    color="primary"
                                >
                                    DETALHADO
                                    <v-icon class="ml-2">cloud_download</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Relatório de compilação dos status e das
                                    principais informações dos títulos
                                    filtrados.
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-card-actions ref="ofertas">
                    <v-card-title class="grey--text font-weight-black title"
                        >2.1.3 Oferta por Título</v-card-title
                    >
                </v-card-actions>
                <v-card-text>
                    A funcionalidade de Criar Oferta dentro do título permite
                    rápido acesso para criação ou edição de ofertas existentes
                    para o título selecionado.
                </v-card-text>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-7.png"
                        class="ma-3"
                        max-height="700"
                        max-width="700"
                        src="@/assets/manual/screen-manual-7.png"
                    ></v-img>
                </v-col>
                <v-col md="4">
                    <v-card-title class="grey--text font-weight-black title"
                        >Função Criar Oferta</v-card-title
                    >
                    <v-card-text>
                        Dentro da tela do Título, é possível acessar o botão
                        Criar Oferta e gerar uma oferta exclusivamente para o
                        título em questão
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="4">
                    <v-card-title class="grey--text font-weight-black title"
                        >Tela: Criar Nova Oferta</v-card-title
                    >
                    <v-card-text>
                        Dentro da tela de ofertas, você pode inserir todos os
                        parâmetros da funcionalidade a ser mostrado para o
                        devedor, como por exemplo: porcentagem de desconto
                        aplicado, valor do desconto em reais (R$), se oferece
                        parcelamento em cartão ou boleto, Data de Início e data
                        de fim da oferta.
                    </v-card-text>
                    <v-card-text>
                        Por fim, como parte opcional, pode ativar o envio de uma
                        mensagem SMS ao devedor contendo informações
                        personalizáveis no corpo da mensagem.
                    </v-card-text>
                </v-col>
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-8.png"
                        class="ma-3"
                        max-height="700"
                        max-width="700"
                        src="@/assets/manual/screen-manual-8.png"
                    ></v-img>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-card-title class="grey--text font-weight-black title"
                    >2.1.4 Renegociação</v-card-title
                >
                <v-card-text>
                    A tela de Renegociação, que pode ser acessada através do
                    menu lateral permite rápido acesso a informações e status de
                    ofertas gerais criadas para o Apresentante. Além de permitir
                    o gerenciamento de todas as ofertas gerais existentes no
                    sistema.
                </v-card-text>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-9.png"
                        class="ma-3"
                        max-height="700"
                        max-width="700"
                        src="@/assets/manual/screen-manual-9.png"
                    ></v-img>
                </v-col>
                <v-col md="4">
                    <v-card-title class="grey--text font-weight-black title"
                        >Listar Renegociações</v-card-title
                    >
                    <v-card-text>
                        Utilize essa tela para realizar buscas dentro da base de
                        Renegociações criadas no sistema.
                    </v-card-text>
                    <v-btn
                        class=""
                        dense
                        color="primary white--text"
                        width="100%"
                    >
                        filtrar
                        <v-icon class="ml-2">search</v-icon>
                    </v-btn>
                    <v-card-text>
                        Essa opção mostra na tela todos os resultados
                        existentes, ou seja, irá mostrar todas as ofertas
                        existentes no sistema, podendo utilizar o filtro para
                        trazer resultados mais detalhados
                    </v-card-text>
                    <v-btn
                        class=""
                        dense
                        color="success white--text"
                        width="100%"
                    >
                        Nova oferta
                        <v-icon class="ml-2">add</v-icon>
                    </v-btn>
                    <v-card-text>
                        Essa opção irá gerar o procedimento de criação de uma
                        nova oferta, onde serão informados os dados e parametros
                        para a nova oferta a ser criada.
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="4">
                    <v-card-title class="grey--text font-weight-black title"
                        >Criar Nova Oferta</v-card-title
                    >
                    <v-card-text>
                        Dentro da tela Renegociação, através do botão Criar Nova
                        Oferta, você poderá inserir uma nova oferta para todos
                        os devedores que estarão inclusos nas condições que
                        serão definidas a partir da faixa de valores. ( Exemplo:
                        de R$ 100,00 a R$ 2.000,00)
                    </v-card-text>
                    <v-card-text>
                        Por fim, como parte opcional, pode ativar o envio de uma
                        mensagem SMS ao devedor contendo informações
                        personalizáveis no corpo da mensagem.
                    </v-card-text>
                </v-col>
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-10.png"
                        class="ma-3"
                        max-height="700"
                        max-width="700"
                        src="@/assets/manual/screen-manual-10.png"
                    ></v-img>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-card-actions ref="enviarSms">
                    <v-card-title class="grey--text font-weight-black title"
                        >2.1.5 Enviar SMS</v-card-title
                    >
                </v-card-actions>
                <v-card-text>
                    A tela Enviar SMS, permite listar, editar e criar novos
                    templates para envio de Mensagens SMS.
                </v-card-text>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-11.png"
                        class="ma-3"
                        max-height="700"
                        max-width="700"
                        src="@/assets/manual/screen-manual-11.png"
                    ></v-img>
                </v-col>
                <v-col md="4">
                    <v-card-title class="grey--text font-weight-black title"
                        >Listar SMS's</v-card-title
                    >
                    <v-card-text>
                        Utilize essa tela para realizar buscas dentro da base de
                        SMS's criados no sistema.
                    </v-card-text>
                    <v-btn
                        class=""
                        dense
                        color="primary white--text"
                        width="100%"
                    >
                        filtrar
                        <v-icon class="ml-2">search</v-icon>
                    </v-btn>
                    <v-card-text>
                        Essa opção mostra na tela todos os resultados
                        existentes, ou seja, irá mostrar todas as ofertas
                        existentes no sistema, podendo utilizar o filtro para
                        trazer resultados mais detalhados
                    </v-card-text>
                    <v-btn
                        class=""
                        dense
                        color="success white--text"
                        width="100%"
                    >
                        Criar Novo SMS
                        <v-icon class="ml-2">add</v-icon>
                    </v-btn>
                    <v-card-text>
                        Essa opção irá abrir na tela uma nova mensagem SMS, onde
                        poderá inserir os parâmetros e detalhes do envio. Também
                        poderá personalizar a mensagem a ser enviada.
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-card-title class="grey--text font-weight-black title"
                    >2.1.6 Gerenciar Usuários do Sistema</v-card-title
                >
                <v-card-text>
                    A tela Listar Usuários permite criar, editar e listar
                    usuários para gerenciar o sistema Recuperi. A listagem de
                    usuários é gerada automaticamente na tela, eliminando a
                    necessidade de preenchimento de filtros ou clique de botão.
                </v-card-text>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-12.png"
                        class="ma-3"
                        max-height="750"
                        max-width="750"
                        src="@/assets/manual/screen-manual-12.png"
                    ></v-img>
                </v-col>
                <v-col md="4" style="display: grid">
                    <v-card-title class="grey--text font-weight-black title"
                        >Listar Usuários</v-card-title
                    >
                    <v-card-text>
                        Utilize essa tela para gerenciar os usuários. A listagem
                        exibe o nome e e-mail/login do usuário, bem como seu
                        status (ativo ou inativo) e o Tipo do Usuário, que será
                        detalhado posteriormente. Além disso, é possível
                        consultar o <b>Histórico</b> de ações do Usuário no
                        sistema, utilizando o botão na coluna "Histórico". Ao
                        final da linha, temos o botão de editar.
                    </v-card-text>
                    <v-btn
                        class=""
                        dense
                        color="success white--text"
                        width="100%"
                    >
                        Criar Novo Usuário
                        <v-icon class="ml-2">add</v-icon>
                    </v-btn>
                    <v-card-text>
                        Essa opção permite a criação de novos usuários no
                        sistema definindo suas limitações e autorizações como
                        Admin ou Usuário Ativo/Inativo.
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row
                class="ma-3 pa-3"
                style="display: flex; justify-content: center; align-items: center;"
            >
                <v-col md="4" style="display: grid">
                    <v-card-title class="grey--text font-weight-black title"
                        >Criar Usuário</v-card-title
                    >
                    <v-card-text>
                        Esta é a tela de criação de Usuário. Nela, é possível
                        visualizar as permissões que cada Tipo de Usuário
                        possui, e inserir os dados para a criação de um novo
                        usuário do sistema. Caso deseje, também é possível
                        delimitar um <b>Agrupador</b> para este Usuário, o que
                        irá delimitar a visibilidade que ele possui dos Títulos
                        na Plataforma. Para mais detalhes sobre os Agrupadores,
                        verificar o tópico <b>2.1.8</b> <br /><br />A criação de
                        senha é opcional, porém, o usuário deverá possuir um
                        e-mail válido, pois será necessário que ele redefina a
                        senha no primeiro acesso. Além disso, utilizando o ícone
                        de "chave", é possível atribuir automaticamente uma
                        senha que já respeita os padrões de segurança do
                        sistema.<br /><br /><b>Importante:</b>
                        apenas Usuários com permissão de
                        <b>administrador</b> podem criar usuários.
                    </v-card-text>
                    <br />
                    <v-btn
                        dense
                        color="primary white--text"
                        width="100%"
                        @click="ativaModalPermissoes"
                    >
                        Ver Permissões
                    </v-btn>
                    <v-card-text>
                        Este botão permite visualizar as permissões atribuídas a
                        cada Tipo de Usuário. <br /><br />Os
                        <b>Tipos de Usuário</b> disponíveis atualmente são
                        <b>admin, basic e lite</b>. Para o usuário <b>lite</b>,
                        há também a opção <b>somente leitura</b>, destinada a
                        usuários que devem ter permissão apenas de visualização
                        de títulos.
                    </v-card-text>
                </v-col>
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-20.png"
                        class="ma-3"
                        max-height="750"
                        max-width="750"
                        src="@/assets/manual/screen-manual-20.png"
                    ></v-img>
                </v-col>
            </v-row>
            <v-dialog
                v-model="modalPermissoes"
                ref="dialog"
                max-width="60vw"
                max-height="60vh"
            >
                <v-toolbar flat dark color="primary">
                    <v-btn icon dark @click="modalPermissoes = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-row>
                        <v-col cols="1" sm="9" class="d-flex">
                            <v-toolbar-title>
                                Permissões de Usuário
                            </v-toolbar-title>
                        </v-col>
                        <v-col cols="1" sm="3" class="d-flex">
                            <v-btn
                                class="white primary--text"
                                @click="generatePDF"
                                >Exportar em PDF</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-card class="py-4 px-8" style="border-radius: 0">
                    <v-card-text
                        class="mx-0 mt-0 mb-3 pa-0"
                        style="font-weight: 500;"
                    >
                        Confira na tabela abaixo os detalhes das permissões para
                        cada usuário:
                    </v-card-text>
                    <v-card>
                        <v-data-table
                            ref="myTable"
                            :headers="headersPermissoes"
                            :items="itensPermissoes"
                            show-group-by
                            :footer-props="{
                                itemsPerPageOptions: [100, 150, 200],
                                itemsPerPageText: 'Itens por página:'
                            }"
                            group-by="category"
                            height="60vh"
                        >
                            <template
                                v-slot:[`item.admin`]="{
                                    item
                                }"
                            >
                                <v-chip :color="getColor(item.admin)">
                                    <v-icon>{{ getBolean(item.admin) }}</v-icon>
                                </v-chip>
                            </template>
                            <template
                                v-slot:[`item.basic`]="{
                                    item
                                }"
                            >
                                <v-chip :color="getColor(item.basic)">
                                    <v-icon>{{ getBolean(item.basic) }}</v-icon>
                                </v-chip>
                            </template>
                            <template
                                v-slot:[`item.lite`]="{
                                    item
                                }"
                            >
                                <v-chip :color="getColor(item.lite)">
                                    <v-icon>{{ getBolean(item.lite) }}</v-icon>
                                </v-chip>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-card>
            </v-dialog>
            <v-card v-show="false">
                <v-data-table
                    ref="myTableShow"
                    :headers="headersPermissoes"
                    :items="itensPermissoes"
                    show-group-by
                    :footer-props="{
                        itemsPerPageOptions: [100, 150, 200],
                        itemsPerPageText: 'Itens por página:'
                    }"
                    group-by="category"
                    height="100%"
                >
                    <template
                        v-slot:[`item.admin`]="{
                            item
                        }"
                    >
                        <v-chip :color="getColor(item.admin)">
                            <v-icon>{{ getBolean(item.admin) }}</v-icon>
                        </v-chip>
                    </template>
                    <template
                        v-slot:[`item.basic`]="{
                            item
                        }"
                    >
                        <v-chip :color="getColor(item.basic)">
                            <v-icon>{{ getBolean(item.basic) }}</v-icon>
                        </v-chip>
                    </template>
                    <template
                        v-slot:[`item.lite`]="{
                            item
                        }"
                    >
                        <v-chip :color="getColor(item.lite)">
                            <v-icon>{{ getBolean(item.lite) }}</v-icon>
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
            <v-row
                class="ma-3 pa-3"
                style="display: flex; justify-content: center; align-items: center;"
            >
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-21.png"
                        class="ma-3"
                        max-height="750"
                        max-width="750"
                        src="@/assets/manual/screen-manual-21.png"
                    ></v-img>
                </v-col>
                <v-col md="4" style="display: grid">
                    <v-card-title class="grey--text font-weight-black title"
                        >Editar Usuário</v-card-title
                    >
                    <v-card-text>
                        Esta é a tela de edição de Usuário. Nela, é possível
                        alterar os dados de um usuário já cadastrado no sistema.
                        <br /><br />
                        <v-btn dense width="100%" class="grey white--text">
                            <v-icon
                                medium
                                class="mr-2"
                                :style="{ color: 'white' }"
                                >edit</v-icon
                            >Editar
                        </v-btn>
                    </v-card-text>
                    <v-card-text>
                        A alteração de senha é opcional, assim como na criação,
                        mas é possível que um administrador ajuste a senha de um
                        usuário se necessário. A funcionalidade de gerar senha
                        automaticamente, presente na criação de usuário, também
                        está presente na edição.<br /><br /><b>Importante:</b>
                        apenas Usuários com permissão de
                        <b>administrador</b> podem editar usuários.
                    </v-card-text>
                </v-col>
            </v-row>
            <v-card-actions ref="alteracoesLote">
                <v-row class="ma-3 pa-3">
                    <v-card-title class="grey--text font-weight-black title"
                        >2.1.7 Alterações de Títulos em lote</v-card-title
                    >
                    <v-card-text>
                        A tela alterações de títulos em lote permitir realizar
                        alterações em massa para determinados títulos no
                        sistema, operações como: Cancelamento, Anuência e
                        Desistência de titulos selecionados. Através dessa
                        função, também é possível realizar o upload de um
                        arquivo em formato XLS/XLSX ou XLSM, e após a leitura do
                        arquivo, extrair títulos para executar o comando
                        selecionado.
                    </v-card-text>
                </v-row>
            </v-card-actions>
            <v-row class="ma-3 pa-3">
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-13.png"
                        class="ma-3"
                        max-height="700"
                        max-width="700"
                        src="@/assets/manual/screen-manual-13.png"
                    ></v-img>
                </v-col>
                <v-col md="4">
                    <v-card-title class="grey--text font-weight-black title"
                        >Cancelamento em Lote</v-card-title
                    >
                    <v-card-text>
                        Utilize essa tela para realizar ações de cancelamento em
                        lote para títulos do sistema.
                    </v-card-text>
                    <v-btn
                        class=""
                        dense
                        color="success white--text"
                        width="100%"
                    >
                        Passo a Passo
                        <v-icon class="ml-2">mdi-chevron-down</v-icon>
                    </v-btn>
                    <v-card-text>
                        Dica: o cancelamento pode ser solicitado somente para os
                        títulos com os seguintes status: 102 Protestado, 109
                        Protestado por Edital, 123 Protestado por Edital(RJ/BA)
                        , 250 Solicitado Anuência, 251 Enviado à Anuência, 144
                        Anuência confirmada em Cartório
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="6">
                    <v-card-title class="grey--text font-weight-black title"
                        >Passo 1</v-card-title
                    >
                    <v-card-text>
                        No menu lateral do painel, clique em Alterações Lote, e
                        em seguida na opção que deseja realizar: Cancelamento,
                        Desistência ou Anuência.
                    </v-card-text>
                    <v-card-title class="grey--text font-weight-black title"
                        >Passo 2</v-card-title
                    >
                    <v-card-text>
                        Para realizar esse procedimento existem 2 opções: a
                        primeira opção é através do campo de busca, onde através
                        desse campo de texto o usuário pode colar uma sequência
                        de números (títulos) separados por virgula (",") e
                        clicar no botão "Filtrar". O sistema irá trazer em tela
                        todos os resultados encontrados.
                    </v-card-text>
                    <v-card-text>
                        A segunda opção para realizar a ação, é através de um
                        upload de Arquivo: Na tela do procedimento que deseja
                        executar, faça o Download do arquivo modelo através do
                        botão "baixar modelo", ao baixar o modelo, o usuário
                        poderá colar em sequencia todos os títulos que deseja
                        realizar o cancelamento. Para realiar o upload de
                        arquivo, vamos exemplificar nos próximos passos o
                        procedimento.
                    </v-card-text>
                </v-col>
                <v-col md="6">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-14.png"
                        class="ma-3"
                        max-height="700"
                        max-width="700"
                        src="@/assets/manual/screen-manual-14.png"
                    ></v-img>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="6">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-15.png"
                        class="ma-3"
                        max-height="700"
                        max-width="700"
                        src="@/assets/manual/screen-manual-15.png"
                    ></v-img>
                </v-col>
                <v-col md="6">
                    <v-card-title class="grey--text font-weight-black title"
                        >Passo 3</v-card-title
                    >
                    <v-card-text>
                        Ao abrir o template de upload, o usuário irá colocar os
                        títulos na coluna que deseja fazer o procedimento, linha
                        após linha, cada número do título deve ser inserido em 1
                        linha. Utilize apenas a coluna "ds_nosso_numero" como na
                        imagem exemplo ao lado.
                    </v-card-text>
                    <v-card-title class="grey--text font-weight-black title"
                        >Passo 4</v-card-title
                    >
                    <v-card-text>
                        Após incluir todos os títulos que deseja realizar a
                        ação, salve o arquivo e feche para realizar o upload
                        dentro do painel. Exemplo será mostrado no próximo
                        passo.
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="6">
                    <v-card-title class="grey--text font-weight-black title"
                        >Passo 5</v-card-title
                    >
                    <v-card-text>
                        Através do campo de upload, insira o arquivo XLS, XLSX
                        ou XLSM que será utilizado para aplicar a ação no
                        sistema, após selecionar o arquivo dentro das pastas de
                        seu computador, nosso verificador irá fazer a busca de
                        títulos e mostrará quantos resultados foram encontrados
                        no campo "Títulos verificados:", conforme a imagem ao
                        lado.
                    </v-card-text>
                    <v-card-title class="grey--text font-weight-black title"
                        >Passo 6</v-card-title
                    >
                    <v-card-text>
                        Após verificar os títulos que foram identificados no
                        arquivo, clique em filtrar, o painel irá trazer na tela
                        os resultados no formato lista, onde o Usuário tem a
                        opção de selecionar apenas os títulos desejados ou todos
                        através de um comando no final da tela.
                    </v-card-text>
                </v-col>
                <v-col md="6">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-16.png"
                        class="ma-3"
                        max-height="700"
                        max-width="700"
                        src="@/assets/manual/screen-manual-16.png"
                    ></v-img>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="6">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-17.png"
                        class="ma-3"
                        max-height="700"
                        max-width="700"
                        src="@/assets/manual/screen-manual-17.png"
                    ></v-img>
                </v-col>
                <v-col md="6">
                    <v-card-title class="grey--text font-weight-black title"
                        >Passo 7</v-card-title
                    >
                    <v-card-text>
                        Selecione todos os títulos que deseja efetuar o
                        cancelamento, caso queira selecionar todos os títulos do
                        resultado de uma só vez, utilize o comando em tela.
                    </v-card-text>
                    <v-card-title class="grey--text font-weight-black title"
                        >Passo 8</v-card-title
                    >
                    <v-card-text>
                        Após revisar os títulos selecionados, aplique o comando
                        de Cancelamento através do botão "Cancelar selecionados"
                        no final da tela.
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="6">
                    <v-card-title class="grey--text font-weight-black title"
                        >Passo 9</v-card-title
                    >
                    <v-card-text>
                        Selecione todos os títulos que deseja efetuar o
                        cancelamento, caso queira selecionar todos os títulos do
                        resultado de uma só vez, utilize o comando em tela.
                    </v-card-text>
                    <v-card-title class="grey--text font-weight-black title"
                        >Passo 10</v-card-title
                    >
                    <v-card-text>
                        Após revisar os títulos selecionados, aplique o comando
                        de Cancelamento através do botão "Cancelar selecionados"
                        no final da tela.
                    </v-card-text>
                </v-col>
                <v-col md="6">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-18.png"
                        class="ma-3"
                        max-height="700"
                        max-width="700"
                        src="@/assets/manual/screen-manual-18.png"
                    ></v-img>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-manual-19.png"
                        class="ma-3"
                        max-height="700"
                        max-width="700"
                        src="@/assets/manual/screen-manual-19.png"
                    ></v-img>
                </v-col>
                <v-col md="4">
                    <v-card-title class="grey--text font-weight-black title"
                        >Confirmação e Envio</v-card-title
                    >
                    <v-card-text>
                        Irá Mostrar uma tela de Confirmação, com os títulos que
                        estarão recebendo o comando de cancelamento, após
                        revisá-los, clique em confirmar.
                    </v-card-text>
                    <v-card-title class="grey--text font-weight-black title"
                        >Finalizado, faça a verificação</v-card-title
                    >
                    <v-card-text>
                        Para conferir você pode ir em "Consulta de Títulos" e
                        buscar os títulos, filtrando pela data de atualização e
                        colocando o status Solicitado
                        Desistência/Cancelamento/Desistência. Lembrar de fazer
                        um arquivo para cada tipo de solicitação
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-card-title class="grey--text font-weight-black title"
                    >2.1.8 Gerenciar Agrupadores do Sistema</v-card-title
                >
                <v-card-text>
                    A tela Listar Agrupadores permite criar, editar e listar
                    Agrupadores. Assim como no gerenciamento de usuários, a
                    listagem de Agrupadores é gerada automaticamente na tela.
                    Existe um botão para a ressincronização dos dados, que
                    realiza um novo carregamento da tela.
                </v-card-text>
                <v-card-text>
                    O Agrupador consiste basicamente de um código, gerado
                    automaticamente pela nossa plataforma, e um nome para
                    identificação do Agrupador. O <b>conceito</b> de Agrupador
                    foi introduzido na plataforma para permitir que um
                    Apresentante setorize a visualização de seus Títulos. Sendo
                    assim, Títulos vinculados a um Agrupador só poderão ser
                    visualizados e acessados por aqueles Usuários que possuírem
                    o mesmo código de Agrupador atribuído ao Título. Usuários
                    que não possuem nenhum agrupador vinculado podem visualizar
                    Títulos de todos os Agrupadores.
                </v-card-text>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-agrupador-01.png"
                        class="ma-3"
                        max-height="750"
                        max-width="750"
                        src="@/assets/manual/screen-agrupador-01.png"
                    ></v-img>
                </v-col>
                <v-col md="4" style="display: grid">
                    <v-card-title class="grey--text font-weight-black title"
                        >Listar Agrupadores</v-card-title
                    >
                    <v-card-text>
                        Utilize essa tela para gerenciar os Agrupadores. A
                        listagem exibe o ID do Agrupador, além do seu nome de
                        identificação e código. Ao final da linha, temos o botão
                        de editar.
                    </v-card-text>
                    <v-btn
                        class=""
                        dense
                        color="success white--text"
                        width="100%"
                    >
                        Criar Novo Agrupador
                        <v-icon class="ml-2">add</v-icon>
                    </v-btn>
                    <v-card-text>
                        Essa opção permite a criação de novos agrupadores no
                        sistema.
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row
                class="ma-3 pa-3"
                style="display: flex; justify-content: center; align-items: center;"
            >
                <v-col md="4" style="display: grid">
                    <v-card-title class="grey--text font-weight-black title"
                        >Criar Agrupador</v-card-title
                    >
                    <v-card-text>
                        Esta é a tela de criação de Agrupador. É preciso
                        preencher apenas o nome de identificação do Agrupador,
                        uma vez que o código é gerado automaticamente pela nossa
                        plataforma, e ficará disponível para consulta após a
                        conclusão do requerimento de criação de novo
                        Agrupador.<br /><br /><b>Importante:</b>
                        apenas Usuários com permissão de
                        <b>administrador</b> podem gerenciar agrupadores.
                    </v-card-text>
                </v-col>
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-agrupador-02.png"
                        class="ma-3"
                        max-height="750"
                        max-width="750"
                        src="@/assets/manual/screen-agrupador-02.png"
                    ></v-img>
                </v-col>
            </v-row>
            <v-row
                class="ma-3 pa-3"
                style="display: flex; justify-content: center; align-items: center;"
            >
                <v-col md="8">
                    <v-img
                        lazy-src="@/assets/manual/screen-agrupador-03.png"
                        class="ma-3"
                        max-height="750"
                        max-width="750"
                        src="@/assets/manual/screen-agrupador-03.png"
                    ></v-img>
                </v-col>
                <v-col md="4" style="display: grid">
                    <v-card-title class="grey--text font-weight-black title"
                        >Editar Agrupador</v-card-title
                    >
                    <v-card-text>
                        Esta é a tela de edição de Agrupador. Nela, é possível
                        alterar o nome de identificação de um Agrupador já
                        cadastrado no sistema. Não é possível alterar o código,
                        uma vez que ele é gerado automaticamente pelo sistema.
                        <br /><br />
                        <v-btn dense width="100%" class="grey white--text">
                            <v-icon
                                medium
                                class="mr-2"
                                :style="{ color: 'white' }"
                                >edit</v-icon
                            >Editar
                        </v-btn>
                    </v-card-text>
                    <v-card-text>
                        <b>Importante:</b>
                        apenas Usuários com permissão de
                        <b>administrador</b> podem editar usuários.
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-card-text>
                    <b>Sobre o envio de Títulos:</b> ao realizar o upload de
                    Títulos, será possível preencher na planilha de upload ou no
                    envio via API o campo de Agrupador. Quando esse vínculo de
                    Agrupador é feito, apenas usuários que possuírem o mesmo
                    código de Agrupador que o Título poderão visualizá-lo. Além
                    disso, usuários que não possuírem delimitação de Agrupador
                    também conseguem visualizar Títulos com Agrupador (uma vez
                    que eles não possuem essa limitação de visualização na
                    Plataforma)
                </v-card-text>
                <v-card-text>
                    <b>Sobre o vínculo entre Agrupador e Usuário:</b> reforçando
                    a informação do segmento <b>2.1.6</b>, será possível
                    atribuir um Agrupador para um Usuário tanto na Criação
                    quanto na Edição de Usuários. Ao realizar esse vínculo, é
                    possível delimitar o acesso que um Usuário tem aos Títulos
                    do Apresentante, delimitando e segmentando esses acessos. A
                    qualquer momento, é possível remover ou incluir uma
                    delimitação de Agrupador para um usuário previamente
                    cadastrado.
                </v-card-text>
                <v-card-text>
                    <b>Sobre a pesquisa de Títulos com Agrupador:</b> usuários
                    que possuírem um Agrupador definido sempre realizarão
                    pesquisas de Título com o código de Agrupador embutido na
                    busca. Sendo assim, um Usuário que possuir um Agrupador A01,
                    por exemplo, sempre enviará esse dado como filtro na busca.
                    Já os usuários que não possuírem um Agrupador vinculado
                    poderão selecionar um dos Agrupadores cadastrados no
                    Apresentante (caso o Apresentante utilize esse sistema) para
                    realizar suas buscas de Título nas diversas telas do sistema
                    em que a busca de Título é feita com filtros.
                </v-card-text>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-card-title class="grey--text font-weight-black display-1"
                    >3. Nossa API</v-card-title
                >
                <v-card-text>
                    Nossa API REST-based permite o recebimento dos títulos via
                    API (em formato JSON), para sensibilização a partir dos
                    próprios sistemas legados. Essa opção permite integração com
                    a nossa Plataforma, possibilitando maior agilidade por meio
                    da automatização dos procedimentos de protesto. Dessa forma,
                    a integração com nossa API permite que seus sistemas legados
                    enviem diretamente para nossa plataforma as informações para
                    protesto de títulos, bem como realizar as ações de
                    cancelamento, anuência e desistência de protesto. É possível
                    ainda consultar o status e movimentações de títulos já
                    enviados. Destacamos que o apresentante pode se utilizar do
                    Painel em conjunto com a API para acompanhamento, relatórios
                    e envio de ações (desistência, anuência, cancelamento, entre
                    outras). A classificação RESTful é normalmente a serviços
                    que expõe à risca todas as características do REST, como
                    trabalhar stateless, sessionless, entre outras. No nosso
                    caso, por não expormos operações de CRUD com os verbos
                    “add”, “put”, “delete”, por exemplo, consideramos os
                    serviços RESTful, e sim baseado em REST.
                </v-card-text>
                <v-card-text>
                    Em linhas gerais, os sistemas legados realizam as seguintes
                    operações via API:
                </v-card-text>
                <v-col sm="6">
                    <v-card-text class="grey--text font-weight-black title">
                        Envio de Remessa: envio de títulos para protestos. Estes
                        títulos são enviados para oscartórios em todo o Brasil.
                    </v-card-text>
                    <v-card-text>
                        Irregularidades cadastrais: são problemas estruturais
                        nos dados informados. Estasirregularidades, caso
                        existam, já são devolvidas no response da requisição.
                        •Eventos acontecidos nos cartórios: São alterações que o
                        título pode sofrer até oencerramento de seu ciclo, como
                        irregularidades cartoriais*, pagamento dostítulos,
                        cancelamento e anuência. São retornados depois e podem
                        ser consultadospelos endpoints de consulta.
                        *Irregularidades cartoriais: São irregularidades
                        informadas pelos cartórios.
                    </v-card-text>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                    <v-card-text class="grey--text font-weight-black title">
                        Envio de Cancelamentos, Anuências e Desistências: envio
                        destes eventos aos cartórios.
                    </v-card-text>
                    <v-card-text>
                        Cancelamento: quando enviado, o apresentante deve pagar
                        as custas decancelamento referentes aos emolumentos dos
                        cartórios (uma vez que oapresentante recebeu este valor
                        de seus clientes). •Anuência: o apresentante comunica ao
                        cartório a informação de que o valor dotítulo protestado
                        já foi pago. Cabe ao devedor ir ao cartório para pagar
                        osemolumentos e retirar o protesto. •Desistência: envio
                        de tentativa de desistência de protesto, no meio tempo
                        em queum título já foi enviado, mas ainda não foi
                        efetivamente protestado.
                    </v-card-text>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                    <v-card-text class="grey--text font-weight-black title">
                        Consulta de Titulo:
                    </v-card-text>
                    <v-card-text>
                        consulta da base de título que já foi enviada, e permite
                        parâmetros defiltragem. Este endpoint retorna os
                        detalhes dos títulos, assim como todo o histórico
                        deeventos de um título, como datas de envio, aceite,
                        apontamento, pagamento etc.
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-card-title class="grey--text font-weight-black title"
                    >3.1 Documentação
                </v-card-title>
                <v-card-text>
                    Para acessar a documentação completa de API's, acesse o
                    botão abaixo:
                </v-card-text>
                <v-col md="12">
                    <v-card>
                        <v-btn
                            target="_blank"
                            class="white--text"
                            width="100%"
                            dense
                            color="red"
                            href="https://api-doc.recuperi.com.br"
                        >
                            VER DOCUMENTAÇÃO
                            <v-icon class="ml-2">arrow_forward</v-icon>
                        </v-btn>
                        <v-card-text>
                            ou através da URL a seguir:
                            https://api-doc.recuperi.com.br
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-card-title class="grey--text font-weight-black display-1"
                    >4. Validação Básica dos Títulos
                </v-card-title>
                <v-card-text>
                    O protesto deve ser realizado conforme definido pela
                    legislação aplicável e em linha com as práticas adotadas
                    pelos cartórios de protesto. Nesse sentido, nossa Plataforma
                    foi desenvolvida com filtros que revisam o preenchimento de
                    diversos campos – contudo, é de responsabilidade do usuário
                    a veracidade e correto preenchimento das informações para
                    envio dos títulos para protesto. Para evitar a rejeição de
                    títulos pelos cartórios de protesto, algumas validações
                    básicas são feitas pela Plataforma, de acordo com as
                    orientações a seguir:
                </v-card-text>
                <v-row class="ma-3 pa-3">
                    <template>
                        <v-data-table
                            :headers="headers"
                            :items="desserts"
                            :items-per-page="100"
                            class="elevation-1"
                            :hide-default-header="true"
                            :hide-default-footer="true"
                        ></v-data-table>
                    </template>
                </v-row>
                <v-card-text>
                    *Nome do Devedor e Endereço do Devedor: se informações foram
                    enviadas com tamanhosuperior ao limite de 45 caracteres, a
                    Plataforma não os rejeita, mas aplica regras automáticas de
                    encurtamento.
                </v-card-text>
                <v-card-text>
                    Caso não seja possível a redução para 45 caracteres e a
                    integração seja via EXCEL, o título fica “Em Revisão”. É
                    possível editar estes campos manualmente para cumprir com o
                    limite de 45 caracteres para liberar o seu envio.
                </v-card-text>
                <v-card-text>
                    Já no caso da integração via API, quando o limite de 45
                    caracteres não for atingido, o sistema retorna com
                    irregularidade e o título precisa ser reenviado
                    posteriormente, com as correções necessárias.
                </v-card-text>
                <v-card-text>
                    ** Essas informações são fixas e disponibilizadas pela área
                    Jurídica da Recuperi. Devem ser enviadas conforme
                    orientação, para cada apresentante.
                </v-card-text>
                <v-card-title class="grey--text font-weight-black display-1"
                    >5. Anexo: Glossário
                </v-card-title>
                <v-card-text>
                    Anuência: similar ao Cancelamento, resulta no cancelamento
                    do registro do protesto. Contudo, nessa hipótese apenas os
                    valores protestados (ou outros valores, conforme renegociado
                    com o Devedor) foram pagos pelo Devedor ao Apresentante –
                    caberá ao Devedor realizar o pagamento dos emolumentos
                    diretamente aos cartórios.
                </v-card-text>
                <v-card-text>
                    Apontamento: procedimento em que o Tabelião analisa o
                    cumprimento dos requisitos legais do título apresentado para
                    protesto. Ocorre antes da intimação do Devedor para fins de
                    protesto.
                </v-card-text>
                <v-card-text>
                    Apresentante: denominação da empresa credora cadastrada
                    perante os cartórios de protesto/Recuperi.
                </v-card-text>
                <v-card-text>
                    Cancelamento: trata-se do cancelamento do registro do
                    protesto. Nessa hipótese, tanto os valores protestados (ou
                    outros valores, conforme renegociado com o Devedor) quanto
                    os emolumentos foram pagos ao Apresentante – caberá ao
                    Apresentante realizar a transferência dos valores devidos
                    aos cartórios.
                </v-card-text>
                <v-card-text>
                    Desistência: antes de efetuado o protesto, o Apresentante
                    poderá desistir do procedimento, com ou sem pagamento de
                    emolumentos a depender das ações já realizadas pelo cartório
                    de protesto (por exemplo, se o Devedor já foi notificado).
                </v-card-text>
                <v-card-text>
                    Emolumentos: taxas remuneratórias de serviços públicos
                    (também conhecidas como “custos do cartório”). Devem ser
                    pagas pelo Devedor (na Anuência) ou pelo Apresentante (no
                    Cancelamento ou Desistência, quando aplicável).
                </v-card-text>
                <v-card-text>
                    Protesto: é o ato formal e solene pelo qual se prova a
                    inadimplência e o descumprimento de obrigação originada em
                    títulos e outros documentos de dívida. O Protesto pode ser
                    realizado por indicação, ou seja, apenas as informações do
                    título são enviadas, sem a apresentação do documento
                    original fisicamente.
                </v-card-text>
                <v-card-text>
                    Sustado Judicialmente: Título sustado por ordem judicial,
                    ocorre antes da lavratura do protesto, até descisão final do
                    processo.
                </v-card-text>
                <v-card-text>
                    Aceito ( L.C. e Duplicata): Títulos aceitos, utilizado
                    Duplicatas e letras de Câmbio, para aceite do sacado.
                </v-card-text>
                <v-card-text>
                    Protestado por Edital (RJ/BA): Título protestado através da
                    tentativa de intimação via edital, código de ocorrência
                    utilizado somente nos estados BA e RJ.
                </v-card-text>
                <v-card-text>
                    Retirado por Edital: Retirada apresentada via edital, mesmo
                    efeito da retirada via arquivo DP.
                </v-card-text>
                <v-card-text>
                    Cancelado Terceiro: Protesto de terceiro cancelado, através
                    de anuência e/ou via arquivo AC/CP.
                </v-card-text>
                <v-card-text>
                    Desistência por Liquidação Bancária: Título já quitado,
                    apresentante desiste de encaminhar título a protesto.
                </v-card-text>
                <v-card-text>
                    Sustado Definitivo: Título sustado definitivamente, ocorre
                    após o término do processo de sustação judicial.
                </v-card-text>
                <v-card-title class="grey--text font-weight-black display-1"
                    >6. Anexo: Irregularidades
                </v-card-title>
            </v-row>
            <v-row class="ma-3 pa-3">
                <template>
                    <v-data-table
                        :headers="irregularidadesHead"
                        :items="irregularidadesDesc"
                        :items-per-page="100"
                        :hide-default-header="true"
                        :hide-default-footer="true"
                        class="elevation-1 ma-3 pa-3"
                    ></v-data-table>
                </template>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-card-title class="grey--text font-weight-black display-1">
                    7. Anexo: Dicionário de Campos
                </v-card-title>
                <template>
                    <v-data-table
                        :headers="dicionarioHead"
                        :items="dicionarioDesc"
                        :items-per-page="100"
                        class="elevation-1 ma-3 pa-3"
                        :hide-default-header="true"
                        :hide-default-footer="true"
                    ></v-data-table>
                </template>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-card-title class="grey--text font-weight-black display-1">
                    8. Anexo: Tabela de tipos de espécies de títulos
                </v-card-title>
                <template>
                    <v-data-table
                        :headers="headerEspecies"
                        :items="especies"
                        :items-per-page="100"
                        class="elevation-1 ma-3 pa-3"
                        hide-default-footer
                    ></v-data-table>
                </template>
            </v-row>
        </v-card>
    </div>
</template>
<script>
import permissoes from '@/helpers/permissoesUsuarios';
import html2pdf from 'html2pdf.js';

export default {
    data() {
        return {
            headersPermissoes: [
                {
                    text: 'Funcionalidade',
                    align: 'start',
                    sortable: false,
                    value: 'funcionalidade',
                    groupable: false
                },
                {
                    text: 'Admin',
                    align: 'start',
                    sortable: false,
                    value: 'admin',
                    groupable: false
                },
                { text: 'Basic', value: 'basic', groupable: false },
                { text: 'Lite', value: 'lite', groupable: false }
            ],
            itensPermissoes: permissoes,
            modalPermissoes: false,
            headers: [
                {
                    text: 'Campo',
                    align: 'start',
                    sortable: false,
                    value: 'campo'
                },
                { text: 'Informação', value: 'informacao' }
            ],
            desserts: [
                {
                    campo: 'Código do Apresentante',
                    informacao:
                        '3 caracteres. Enviar conforme homologado junto à Recuperi.'
                },
                {
                    campo: 'Nome do Devedor',
                    informacao: 'Entre 3 e 45 caracteres*'
                },
                {
                    campo: 'Endereço do Devedor',
                    informacao: 'Entre 3 e 45 caracteres*, incluindo numeração'
                },
                {
                    campo: 'Bairro do Devedor',
                    informacao: 'Informar'
                },
                {
                    campo: 'CEP do Devedor',
                    informacao: 'Informar'
                },
                {
                    campo: 'Cidade do Devedor',
                    informacao: 'Informar'
                },
                {
                    campo: 'UF do Devedor',
                    informacao: 'Informar'
                },
                {
                    campo: 'Tipo de identificação do Devedor',
                    informacao: '1-CNPJ ou 2-CPF'
                },
                {
                    campo: 'Número de Identificação do Devedor',
                    informacao: 'informar CNPJ ou CPF válidos'
                },
                {
                    campo: 'Nome do Sacador',
                    informacao: '**'
                },
                {
                    campo: 'CNPJ do Sacador',
                    informacao: '**'
                },
                {
                    campo: 'Endereço do Sacador',
                    informacao: '**'
                },
                {
                    campo: 'Cidade do Sacador',
                    informacao: '**'
                },
                {
                    campo: 'UF do Sacador',
                    informacao: '**'
                },
                {
                    campo: 'Nome Credor',
                    informacao: '**'
                },
                {
                    campo: 'Espécie',
                    informacao:
                        'Informar a espécie do título (ex. CBI, DMI etc.)'
                },
                {
                    campo: 'Aceite',
                    informacao:
                        'Informações sobre aceite (conforme definido juridicamente no título)'
                },
                {
                    campo: 'Declaração',
                    informacao:
                        'Informações sobre declaração (conforme definido juridicamente no título)'
                },
                {
                    campo: 'Endosso',
                    informacao:
                        'Informações sobre endosso (conforme definido juridicamente no título)'
                },
                {
                    campo: 'Número do Título',
                    informacao: 'Informar'
                },
                {
                    campo: 'Número da Parcela',
                    informacao:
                        'É obrigatório (ex. 3) ou “1” para parcela única/à vista'
                },
                {
                    campo: 'Nosso Número',
                    informacao:
                        'Informar Nosso Número do sistema de cobrança. Caso ainda não tenha, compor concatenando Número do Título + “/” + Parcela (ex: 1058/1)'
                },
                {
                    campo: 'Valor do Título',
                    informacao:
                        'É o valor original do título, sempre acima de zero'
                },
                {
                    campo: 'Valor Saldo a Protestar',
                    informacao:
                        'é o valor a ser protestado, já com juros, mora, multa oueventuais abatimentos, sempre acima de zero'
                },
                {
                    campo: 'Data de Emissão',
                    informacao: 'Deve ser menor que Data de Vencimento•'
                },
                {
                    campo: 'Data de Vencimento',
                    informacao:
                        'Deve ser maior que Data de Emissão e já deve estar vencido.'
                },
                {
                    campo: 'Email do Devedor',
                    informacao:
                        'Opcional e não é enviado para os cartórios, mas pode servircomo chave de pesquisa•'
                },
                {
                    campo: 'Telefone do Devedor',
                    informacao:
                        'Opcional e não é enviado para os cartórios, mas pode servircomo chave de pesquisa'
                }
            ],
            irregularidadesHead: [
                {
                    text: 'Código',
                    align: 'start',
                    sortable: false,
                    value: 'codigo'
                },
                { text: 'Descrição Irregularidade', value: 'descricao' }
            ],
            irregularidadesDesc: [
                {
                    codigo: '01',
                    descricao:
                        'Data da apresentação inferior a data de vencimento'
                },
                {
                    codigo: '02',
                    descricao: 'Falta de comprovante da prestação de serviço'
                },
                {
                    codigo: '03',
                    descricao: 'Nome do sacado incompleto/incorreto'
                },
                {
                    codigo: '04',
                    descricao: 'Nome do cedente incompleto/incorreto'
                },
                {
                    codigo: '05',
                    descricao: 'Nome do sacador incompleto/incorreto'
                },
                {
                    codigo: '06',
                    descricao: 'Endereço do sacado insuficiente'
                },
                {
                    codigo: '07',
                    descricao: 'CNPJ/CPF do sacado invalido/incorreto'
                },
                {
                    codigo: '08',
                    descricao:
                        'CNPJ/CPF incompatível com o nome do sacado/sacador/avalista'
                },
                {
                    codigo: '09',
                    descricao:
                        'CNPJ/CPF do sacado incompatível com o tipo de documento'
                },
                {
                    codigo: '10',
                    descricao: 'CNPJ/CPF do sacador incompatível com a espécie'
                },
                {
                    codigo: '11',
                    descricao: 'Título aceito sem a assinatura do sacado'
                },
                {
                    codigo: '12',
                    descricao: 'Título aceito rasurado ou rasgado'
                },
                {
                    codigo: '13',
                    descricao: 'Título aceito falta título (ag ced: enviar)'
                },
                {
                    codigo: '14',
                    descricao: 'CEP incorreto'
                },
                {
                    codigo: '15',
                    descricao: 'Praça de pagamento incompatível com endereço'
                },
                {
                    codigo: '16',
                    descricao: 'Número do título invalido'
                },
                {
                    codigo: '17',
                    descricao: 'Título sem endosso do cedente ou irregular'
                },
                {
                    codigo: '18',
                    descricao: 'Falta data de emissão do título'
                },
                {
                    codigo: '19',
                    descricao:
                        'Título aceito: valor extenso diferente do valor numérico'
                },
                {
                    codigo: '20',
                    descricao: 'Data de emissão posterior ao vencimento'
                },
                {
                    codigo: '21',
                    descricao: 'Espécie invalida para protesto'
                },
                {
                    codigo: '22',
                    descricao:
                        'CEP do sacado incompatível com a praça de protesto'
                },
                {
                    codigo: '23',
                    descricao: 'Falta espécie do título'
                },
                {
                    codigo: '24',
                    descricao: 'Saldo maior que o valor do título'
                },
                {
                    codigo: '25',
                    descricao: 'Tipo de endosso invalido'
                },
                {
                    codigo: '26',
                    descricao: 'Devolvido por ordem judicial'
                },
                {
                    codigo: '27',
                    descricao: 'Dados do título não conferem com disquete'
                },
                {
                    codigo: '28',
                    descricao: 'Sacado e Sacador/Avalista são a mesma pessoa'
                },
                {
                    codigo: '29',
                    descricao: 'Corrigir a espécie do título'
                },
                {
                    codigo: '30',
                    descricao:
                        'Aguardar um dia útil após o vencimento para protestar'
                },
                {
                    codigo: '31',
                    descricao: 'Data do vencimento rasurada'
                },
                {
                    codigo: '32',
                    descricao: 'Vencimento extenso não confere com número'
                },
                {
                    codigo: '33',
                    descricao: 'Falta data de vencimento no título'
                },
                {
                    codigo: '34',
                    descricao:
                        'DM/DMI sem comprovante autenticado ou declaração'
                },
                {
                    codigo: '35',
                    descricao:
                        'Comprovante ilegível para conferência e microfilmagem'
                },
                {
                    codigo: '36',
                    descricao:
                        'Nome solicitado não confere com emitente ou sacado'
                },
                {
                    codigo: '37',
                    descricao:
                        'Confirmar se são 2 emitentes. Se sim, indicar os dados dos 2'
                },
                {
                    codigo: '38',
                    descricao:
                        'Endereço do sacado igual ao do sacador ou do portador'
                },
                {
                    codigo: '39',
                    descricao:
                        'Endereço do apresentante incompleto ou não informado'
                },
                {
                    codigo: '40',
                    descricao: 'Rua / Número inexistente no endereço'
                },
                {
                    codigo: '41',
                    descricao: 'Informar a qualidade do endosso (M ou T)'
                },
                {
                    codigo: '42',
                    descricao: 'Falta endosso do favorecido para o apresentante'
                },
                {
                    codigo: '43',
                    descricao: 'Data da emissão rasurada'
                },
                {
                    codigo: '44',
                    descricao:
                        'Protesto de cheque proibido - motivo 20/25/28/30 ou 35'
                },
                {
                    codigo: '45',
                    descricao: 'Falta assinatura do emitente no cheque'
                },
                {
                    codigo: '46',
                    descricao:
                        'Endereço do emitente no cheque igual ao do banco sacado'
                },
                {
                    codigo: '47',
                    descricao:
                        'Falta o motivo da devolução no cheque ou motivo ilegível'
                },
                {
                    codigo: '48',
                    descricao: 'Falta assinatura do sacador no título'
                },
                {
                    codigo: '49',
                    descricao:
                        'Nome do apresentante não informado/incompleto/incorreto'
                },
                {
                    codigo: '50',
                    descricao: 'Erro de preenchimento do título'
                },
                {
                    codigo: '51',
                    descricao: 'Título com direito de regresso vencido'
                },
                {
                    codigo: '52',
                    descricao: 'Título apresentado em duplicidade'
                },
                {
                    codigo: '53',
                    descricao: 'Título já protestado'
                },
                {
                    codigo: '54',
                    descricao:
                        'Letra de Cambio vencida - falta aceite do sacado'
                },
                {
                    codigo: '55',
                    descricao: 'Falta tradução por tradutor publico'
                },
                {
                    codigo: '56',
                    descricao: 'Falta declaração de saldo assinada no título'
                },
                {
                    codigo: '57',
                    descricao: 'Contrato de Cambio falta conta gráfica'
                },
                {
                    codigo: '58',
                    descricao: 'Ausência do Documento Físico'
                },
                {
                    codigo: '59',
                    descricao: 'Sacado Falecido'
                },
                {
                    codigo: '60',
                    descricao: 'Sacado Apresentou Quitação do Título'
                },
                {
                    codigo: '61',
                    descricao: 'Título de outra jurisdição territorial'
                },
                {
                    codigo: '62',
                    descricao:
                        'Título com emissão anterior a concordata do sacado'
                },
                {
                    codigo: '63',
                    descricao: 'Sacado consta na lista de falência'
                },
                {
                    codigo: '64',
                    descricao: 'Apresentante não aceita publicação de edital'
                },
                {
                    codigo: '65',
                    descricao: 'Dados do sacador em branco ou invalido'
                },
                {
                    codigo: '66',
                    descricao: 'Título sem autorização para protesto por edital'
                },
                {
                    codigo: '67',
                    descricao: 'Valor divergente entre título e comprovante'
                },
                {
                    codigo: '68',
                    descricao:
                        'Condomínio não pode ser protestado para fins falimentares'
                },
                {
                    codigo: 'l1',
                    descricao: 'Falta número da parcela/ número invalido'
                },
                {
                    codigo: 'l2',
                    descricao: 'Falta valor título'
                },
                {
                    codigo: 'l3',
                    descricao: 'Falta valor do saldo título'
                },
                {
                    codigo: 'l4',
                    descricao: 'Uf do devedor inexistente ou não homologada'
                },
                {
                    codigo: 'l5',
                    descricao: 'Cidade do devedor incorreta'
                },
                {
                    codigo: 'l6',
                    descricao: 'Bairro do devedor incorreto'
                },
                {
                    codigo: 'l7',
                    descricao: 'Endereço do sacador incorreto/inexistente'
                },
                {
                    codigo: 'l8',
                    descricao: 'Cidade do sacador incorreto/inexistente'
                },
                {
                    codigo: 'l9',
                    descricao: 'CEP do sacador incorreto'
                },
                {
                    codigo: 'j1',
                    descricao: 'UF do sacador inexistente'
                },
                {
                    codigo: 'j2',
                    descricao: 'Não foi possível obter a comarca para protesto'
                },
                {
                    codigo: 'j3',
                    descricao: 'Título duplicado no arquivo'
                },
                {
                    codigo: 'j4',
                    descricao:
                        'Título já existe no sistema e status não permite protesto'
                },
                {
                    codigo: 'j5',
                    descricao: 'CNPJ do sacador invalido/incorreto'
                },
                {
                    codigo: 'j6',
                    descricao: 'Tipo de declaração inválida'
                },
                {
                    codigo: 'j7',
                    descricao: 'Data de vencimento muito antiga'
                },
                {
                    codigo: 'j8',
                    descricao: 'Não foi possível abreviar o nome do devedor'
                },
                {
                    codigo: 'j9',
                    descricao: 'Não foi possível abreviar o endereço do devedor'
                }
            ],
            dicionarioHead: [
                {
                    text: 'Nome funcional',
                    align: 'start',
                    sortable: false,
                    value: 'nome_funcional'
                },
                { text: 'Nome técnico (Header)', value: 'nome_tecnico' },
                { text: 'Tipo e Tamanho', value: 'tamanho_tipo' },
                { text: 'Descrição e Observação', value: 'descricao' }
            ],
            dicionarioDesc: [
                {
                    nome_funcional: 'Nome do Devedor',
                    nome_tecnico: 'nm_devedor',
                    tamanho_tipo: 'Alpha(45)',
                    descricao: 'Nome do Devedor ou Razão Social'
                },
                {
                    nome_funcional: 'Endereço do Devedor',
                    nome_tecnico: 'ds_endereco_devedor',
                    tamanho_tipo: 'Alpha(45)',
                    descricao:
                        'Endereço do Devedor, contendo logradouro, número e complemento'
                },
                {
                    nome_funcional: 'Bairro do Devedor',
                    nome_tecnico: 'ds_bairro_devedor',
                    tamanho_tipo: 'Alpha(20)',
                    descricao: 'Valor pode ser truncado'
                },
                {
                    nome_funcional: 'CEP do Devedor',
                    nome_tecnico: 'cd_cep_devedor',
                    tamanho_tipo: 'Alpha(9)',
                    descricao:
                        'Informar o CEP com 8 dígitos. Se tiver zeros à esquerda, é preciso informar. Exemplo: 01418-001 ou 01418001'
                },
                {
                    nome_funcional: 'Cidade do Devedor',
                    nome_tecnico: 'nm_cidade_devedor',
                    tamanho_tipo: 'Alpha(20)',
                    descricao: 'Nome da Cidade do Devedor. Pode ser truncado'
                },
                {
                    nome_funcional: 'UF do Devedor',
                    nome_tecnico: 'cd_uf_devedor',
                    tamanho_tipo: 'Alpha(2)',
                    descricao: 'UF do devedor'
                },
                {
                    nome_funcional: 'Tipo documento Devedor',
                    nome_tecnico: 'tp_identificao_devedor',
                    tamanho_tipo: 'Alpha(1)',
                    descricao:
                        'Especifica qual o tipo de documento a ser enviado no campo nu_identificacao_devedor 1 - para CNPJ; 2 - para CPF'
                },
                {
                    nome_funcional: 'CPF/CNPJ do Devedor',
                    nome_tecnico: 'nu_identificao_devedor',
                    tamanho_tipo: 'Alpha(14)',
                    descricao:
                        'Enviar o CNPJ ou CPF, sem formatação. Se houver zeros à esquerda, enviar.'
                },
                {
                    nome_funcional: 'Nome do Sacador',
                    nome_tecnico: 'nm_sacador',
                    tamanho_tipo: 'Alpha(45)',
                    descricao:
                        '(fixo) Informações providas pelo Jurídico/RECUPERI na homologação do cliente.'
                },
                {
                    nome_funcional: 'CNPJ do Sacador',
                    nome_tecnico: 'cnpj_sacador',
                    tamanho_tipo: 'Alpha(14)',
                    descricao:
                        '(fixo) Informações providas pelo Jurídico/RECUPERI na homologação do cliente.'
                },
                {
                    nome_funcional: 'Endereço Sacador',
                    nome_tecnico: 'ds_endereco_sacador',
                    tamanho_tipo: 'Alpha(45)',
                    descricao:
                        '(fixo) Informações providas pelo Jurídico/RECUPERI na homologação do cliente.'
                },
                {
                    nome_funcional: 'Cidade do Sacador',
                    nome_tecnico: 'nm_cidade_sacador',
                    tamanho_tipo: 'Alpha(20)',
                    descricao:
                        '(fixo) Informações providas pelo Jurídico/RECUPERI na homologação do cliente.'
                },
                {
                    nome_funcional: 'CEP do Sacador',
                    nome_tecnico: 'cd_cep_sacador',
                    tamanho_tipo: 'Alpha(9)',
                    descricao:
                        '(fixo) Informações providas pelo Jurídico/RECUPERI na homologação do cliente.'
                },
                {
                    nome_funcional: 'UF do Sacador',
                    nome_tecnico: 'cd_uf_sacador',
                    tamanho_tipo: 'Alpha(2)',
                    descricao:
                        '(fixo) Informações providas pelo Jurídico/RECUPERI na homologação do cliente.'
                },
                {
                    nome_funcional: 'Nome do Cedente / Favorecido',
                    nome_tecnico: 'nm_cedente_favorecido',
                    tamanho_tipo: 'Alpha(45)',
                    descricao:
                        '(fixo) Informações providas pelo Jurídico/RECUPERI na homologação do cliente.'
                },
                {
                    nome_funcional: 'Número do Titulo',
                    nome_tecnico: 'ds_numero_titulo',
                    tamanho_tipo: 'Alpha(11)',
                    descricao: 'Número do Título no sistema legado'
                },
                {
                    nome_funcional: 'Número da Parcela',
                    nome_tecnico: 'nu_parcela',
                    tamanho_tipo: 'Numerico(3)',
                    descricao: 'Parcela que está em atraso'
                },
                {
                    nome_funcional: 'Nosso Número',
                    nome_tecnico: 'ds_nosso_numero',
                    tamanho_tipo: 'Alpha(15)',
                    descricao: 'Nosso Número de identificação para cobrança'
                },
                {
                    nome_funcional: 'Espécie',
                    nome_tecnico: 'cd_especie_titulo',
                    tamanho_tipo: 'Alpha(15)',
                    descricao: 'Espécie do Título para Protesto'
                },
                {
                    nome_funcional: 'Aceite',
                    nome_tecnico: 'tp_aceite',
                    tamanho_tipo: 'Alpha(1)',
                    descricao:
                        '(fixo) Informações providas pelo Jurídico/RECUPERI na homologação do cliente.'
                },
                {
                    nome_funcional: 'Declaração',
                    nome_tecnico: 'tp_declaracao',
                    tamanho_tipo: 'Alpha(1)',
                    descricao:
                        '(fixo) Informações providas pelo Jurídico/RECUPERI na homologação do cliente.'
                },
                {
                    nome_funcional: 'Endosso',
                    nome_tecnico: 'tp_endosso',
                    tamanho_tipo: 'Alpha(1)',
                    descricao:
                        '(fixo) Informações providas pelo Jurídico/RECUPERI na homologação do cliente.'
                },
                {
                    nome_funcional: 'Valor do Título',
                    nome_tecnico: 'vl_titulo',
                    tamanho_tipo: 'Numérico(12,2)',
                    descricao: 'Valor original do título ou parcela'
                },
                {
                    nome_funcional: 'Valor Saldo (Protesto)',
                    nome_tecnico: 'vl_saldo_protesto',
                    tamanho_tipo: 'Numérico(12,2)',
                    descricao:
                        'Valor a ser protestado, já com juros ou abatimentos'
                },
                {
                    nome_funcional: 'Data de emissão do Título',
                    nome_tecnico: 'dt_emissao',
                    tamanho_tipo: 'Alpha(10)',
                    descricao:
                        'Data de Emissão do Título. Formato ISO YYYY-MM-DD'
                },
                {
                    nome_funcional: 'Data de Vencimento da Parcela',
                    nome_tecnico: 'dt_vencimento',
                    tamanho_tipo: 'Alpha(10)',
                    descricao:
                        'Data de Vencimento (já deve estar vencido) . Formato ISO YYYY-MM-DD'
                },
                {
                    nome_funcional: 'E-mail do devedor',
                    nome_tecnico: 'ds_email_devedor',
                    tamanho_tipo: 'Alpha(45)',
                    descricao:
                        'Opcional. Não é enviado para o protesto, mas serve como chave de busca'
                },
                {
                    nome_funcional: 'Telefone do devedor',
                    nome_tecnico: 'nu_telefone_devedor',
                    tamanho_tipo: 'Alpha(20)',
                    descricao:
                        'Opcional. Não é enviado para o protesto, mas serve como chave de busca'
                },
                {
                    nome_funcional: 'Carteira',
                    nome_tecnico: 'cd_carteira',
                    tamanho_tipo: 'Alpha(15)',
                    descricao:
                        'Opcional. Não é enviado para o protesto, mas serve como chave de busca'
                }
            ],
            headerEspecies: [
                {
                    text: 'Espécie',
                    align: 'start',
                    sortable: false,
                    value: 'especie'
                },
                { text: 'Descrição', value: 'descricao' }
            ],
            especies: [
                {
                    especie: 'CBI',
                    descricao: 'Cédula de Crédito Bancário por Indicação'
                },
                {
                    especie: 'CC',
                    descricao: 'Contrato de Câmbio'
                },
                {
                    especie: 'CCB',
                    descricao: 'Cédula de Crédito Bancário'
                },
                {
                    especie: 'CCC',
                    descricao: 'Cédula de Crédito Comercial'
                },
                {
                    especie: 'CCE',
                    descricao: 'Cédula de Crédito a Exportação'
                },
                {
                    especie: 'CCI',
                    descricao: 'Cédula de Crédito Industrial'
                },
                {
                    especie: 'CCR',
                    descricao: 'Cédula de Crédito Rural'
                },
                {
                    especie: 'CD',
                    descricao:
                        'Confissão de Dívida - Apenas para fins de falência (uma declaração do devedor)'
                },
                {
                    especie: 'CDA',
                    descricao: 'Certidão da Dívida Ativa'
                },
                {
                    especie: 'CH',
                    descricao: 'Cheque'
                },
                {
                    especie: 'CHP',
                    descricao: 'Cédula Hipotecária'
                },
                {
                    especie: 'CJV',
                    descricao:
                        'Conta Judicialmente Verificada - Verificação de livro de empresa - protesto de um processo judicial - falência'
                },
                {
                    especie: 'CM',
                    descricao: 'Contrato de Mútuo'
                },
                {
                    especie: 'CPH',
                    descricao: 'Cédula Rural Pignoratícia Hipotecária'
                },
                {
                    especie: 'CPS',
                    descricao:
                        'Conta de Prestação de Serviços - Profissional liberal - declaração do profissional, documentos comprobatórios e notificação do devedor, pelo Cartório de Registro de Títulos e Documentos'
                },
                {
                    especie: 'CRH',
                    descricao: 'Cédula Rural Hipotecária'
                },
                {
                    especie: 'CRP',
                    descricao: 'Cédula Rural Pignoratícia'
                },
                {
                    especie: 'CT',
                    descricao: 'Espécie de Contrato'
                },
                {
                    especie: 'DM',
                    descricao: 'Duplicata de Venda Mercantil'
                },
                {
                    especie: 'DMI',
                    descricao: 'Duplicata de Venda Mercantil por Indicação'
                },
                {
                    especie: 'DR',
                    descricao: 'Duplicata Rural'
                },
                {
                    especie: 'DRI',
                    descricao: 'Duplicata Rural por Indicação'
                },
                {
                    especie: 'DS',
                    descricao: 'Duplicata de Prestação de Serviços - Original'
                },
                {
                    especie: 'DSI',
                    descricao:
                        'Duplicata de Prestação de Serviços por Indicação - Comprovante de prestação dos serviços'
                },
                {
                    especie: 'DV',
                    descricao:
                        'Diversos - Quando não enquadrar na relação acima'
                },
                {
                    especie: 'EC',
                    descricao: 'Encargos Condominiais'
                },
                {
                    especie: 'LC',
                    descricao: 'Letra de Câmbio'
                },
                {
                    especie: 'NCC',
                    descricao: 'Nota de Crédito Comercial'
                },
                {
                    especie: 'NCE',
                    descricao: 'Nota de Crédito a Exportação'
                },
                {
                    especie: 'NCI',
                    descricao: 'Nota de Crédito Industrial'
                },
                {
                    especie: 'NCR',
                    descricao: 'Nota de Crédito Rural'
                },
                {
                    especie: 'NP',
                    descricao: 'Nota Promissória'
                },
                {
                    especie: 'NPR',
                    descricao: 'Nota Promissória Rural'
                },
                {
                    especie: 'RA',
                    descricao:
                        'Recibo de Aluguel - Somente para pessoa jurídica (contrato de aluguel e recibo)'
                },
                {
                    especie: 'SJ',
                    descricao: 'Sentença Judicial'
                },
                {
                    especie: 'TA',
                    descricao: 'Termo de Acordo - Ex. Ação trabalhista'
                },
                {
                    especie: 'TM',
                    descricao: 'Triplicata de Venda Mercantil'
                },
                {
                    especie: 'TM',
                    descricao: 'Triplicata de Venda Mercantil'
                },
                {
                    especie: 'TS',
                    descricao: 'Triplicata de Prestação de Serviços'
                },
                {
                    especie: 'W',
                    descricao: 'Warrant'
                }
            ]
        };
    },
    methods: {
        generatePDF() {
            const options = {
                margin: 1,
                filename: 'permissoes_de_usuario_recuperi.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: {
                    unit: 'in',
                    format: 'letter',
                    orientation: 'portrait',
                    height: document.documentElement.scrollHeight
                }
            };
            const element = this.$refs.myTableShow.$el;
            html2pdf()
                .from(element)
                .set(options)
                .save();
        },
        ativaModalPermissoes() {
            this.modalPermissoes = true;
        },
        getColor(valor) {
            if (valor === true) {
                return 'success';
            } else {
                return 'error';
            }
        },
        getBolean(valor) {
            if (valor === true) {
                return 'mdi-check';
            } else {
                return 'mdi-close';
            }
        }
    }
};
</script>
