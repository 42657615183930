<template>
    <div>
        <!-- Cabeçalho -->
        <Loading :active="salvandoApresentante" :is-full-page="true" />
        <div align="center" justify="center" class="pa-3">
            <v-card align="left" justify="left" class="mb-3">
                <v-card-text class="pa-3 font-weight-light white black--text">
                    <v-icon class="primary--text lighten-2 pa-2">
                        mdi-application-edit-outline </v-icon
                    >Apresentantes > Gerenciar Apresentantes
                </v-card-text>
            </v-card>
            <v-card
                v-if="isPartner !== 1"
                @keyup.native.enter="aplicarFiltros"
                class="pa-3 mb-4"
            >
                <v-row class="pa-3 d-flex align-center">
                    <v-col
                        cols="4"
                        xs="6"
                        sm="6"
                        md="4"
                        lg="4"
                        class="d-flex flex-row pa-0"
                    >
                        <v-text-field
                            class="pa-0 ma-0"
                            name="filtro"
                            outlined
                            label="Buscar Apresentante"
                            type="text"
                            hide-details
                            dense
                            :disabled="loading"
                            v-model="filtroApresentante"
                            data-cy="filtroApresentante"
                        />
                    </v-col>
                    <v-col class="ml-2" md="4">
                        <v-select
                            :items="filtrosSelect"                            
                            label="Filtros"
                            v-model="filterSelected"
                            item-text="name"
                            item-value="value"
                            @change="listarApresentantes"
                        />                        
                    </v-col>
                    <v-col
                        
                        class="d-flex justify-end align-center pa-0"
                    >
                        <v-btn
                            color="blue white--text"
                            @click="dialogCriarApresentanteExperience = true"
                            data-cy="criarApresentanteExperience"
                            :disabled="loading"
                            v-if="subDomain === 'experience'"
                        >
                            Criar Apresentante Experience
                            <v-icon class="ml-2">add</v-icon>
                        </v-btn>
                        <v-btn
                            class="ml-2"
                            color="green white--text"
                            @click="criarApresentante"
                            data-cy="criarApresentante"
                            :disabled="loading"
                        >
                            Criar novo Apresentante
                            <v-icon class="ml-2">add</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <!-- fim do cabeçalho -->
            <!-- Inicio tabela resultados -->
            <Snackbar
                v-if="snackbar"
                :mostrarSnackbar="snackbar"
                :corSnackbar="snackbarColor"
                :mensagemSnackbar="mensagem"
                @fecharSnackbar="snackbar = false"
            />
            <v-data-table
                :headers="headers"
                :items="apresentantes"
                :loading="loading"
                :footer-props="{
                    itemsPerPageOptions: [10, 20, 50, -1],
                    itemsPerPageText: 'Apresentantes por página:'
                }"
                item-key="usuarios"
                class="elevation-1 pa-3"
                :search="filtroApresentante"
            >
                <template v-slot:[`item.cra_nacional`]="{ item }">
                    <v-switch readonly v-model="item.cra_nacional" inset />
                </template>
                <template v-slot:[`item.dt_liberacao_estados`]="{ item }">
                    {{
                        item.dt_liberacao_estados
                            ? new Date(new Date(item.dt_liberacao_estados).setDate(new Date(item.dt_liberacao_estados).getDate() + 1)).toLocaleDateString()
                            : ''
                    }}
                </template>
                <template v-slot:[`item.st_ativo`]="{ item }">
                    <v-switch
                        :color="item.st_ativo != '' ? 'green' : 'red'"
                        readonly
                        v-model="item.st_ativo"
                        inset
                        :style="{
                            color: item.st_ativo != '' ? 'green' : 'red'
                        }"
                    >
                    </v-switch>
                </template>
                <template v-slot:[`item.st_ativo_comando`]="{ item }">
                    <v-icon
                        v-if="item.st_ativo_comando === 0"
                        color="error"
                        >mdi-pause-circle-outline</v-icon
                    >
                    <v-icon v-else color="success">mdi-play-circle-outline</v-icon>
                </template>
                <template v-slot:[`item.st_ativo_remessa`]="{ item }">
                    <v-icon
                        v-if="item.st_ativo_remessa === 0"
                        color="error"
                        >mdi-pause-circle-outline</v-icon
                    >
                    <v-icon v-else color="success">mdi-play-circle-outline</v-icon>
                </template>

                <template v-slot:[`item.st_ativo_negociacao`]="{ item }">
                    <v-icon
                        v-if="item.st_ativo_negociacao === 0"
                        color="error"
                        >mdi-pause-circle-outline</v-icon
                    >
                    <v-icon v-else color="success">mdi-play-circle-outline</v-icon>
                </template>

                <template v-slot:[`item.st_mensageria`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                dark
                                small
                                class="white--text"
                                :style="
                                    item.st_mensageria
                                        ? 'background-color: ' + $vuetify.theme.themes.light.primary
                                        : 'background-color: #b8c4d4'
                                "
                            >
                                <v-icon dark>
                                    mdi-email-newsletter
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{
                                item.st_mensageria
                                    ? 'Apresentante apto para Mensageria'
                                    : 'Apresentante requer ajustes para habilitar Mensageria'
                            }}
                        </span>
                    </v-tooltip>
                </template>
                <template v-slot:[`item.notifica_company_id`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                dark
                                small
                                class="white--text"
                                :style="
                                    item.notifica_company_id
                                        ? 'background-color: ' + $vuetify.theme.themes.light.primary
                                        : 'background-color: #b8c4d4'
                                "
                            >
                                <v-icon dark>
                                    {{
                                        item.notifica_company_id
                                            ? 'mdi-check-bold'
                                            : 'mdi-close-circle-outline'
                                    }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{
                                item.notifica_company_id
                                    ? 'Apresentante habilitado na Notifica'
                                    : 'Apresentante não habilitado na Notifica'
                            }}
                        </span>
                    </v-tooltip>
                </template>
                <template v-slot:[`item.st_protestado`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                dark
                                small
                                class="white--text"
                                :style="
                                    item.st_protestado
                                        ? 'background-color: ' + $vuetify.theme.themes.light.primary
                                        : 'background-color: #b8c4d4'
                                "
                            >
                                <v-icon dark>
                                    {{
                                        item.st_protestado
                                            ? 'mdi-check-bold'
                                            : 'mdi-close-circle-outline'
                                    }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{
                                item.st_protestado
                                    ? 'Apresentante habilitado na Protestado'
                                    : 'Apresentante não habilitado na Protestado'
                            }}
                        </span>
                    </v-tooltip>
                </template>
                <template v-slot:[`item.is_admin`]="{ item }">
                    <v-icon
                        :style="{
                            color: item.is_admin != '' ? 'green' : 'red'
                        }"
                        >{{ getBolean(item.is_admin) }}</v-icon
                    >
                </template>
                <template slot="item.action" slot-scope="props">
                    <v-btn
                        elevation="0"
                        width="90%"
                        :loading="dialogLoading || loading"
                        @click="exibirDetalhe(props.item)"
                        class="mr-2 grey white--text"
                        :data-cy="`editarApresentante${props.index}`"
                    >
                        <v-icon
                            medium
                            class="mr-2"
                            @click="exibirDetalhe(props.item)"
                            :style="{ color: 'white' }"
                            >edit</v-icon
                        >Editar
                    </v-btn>
                </template>
            </v-data-table>
            <!-- Fim tabela resultados -->
        </div>
        <v-dialog
            v-if="dialog"
            v-model="dialog"
            max-width="1200px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Tela Criar Apresentante -->
            <Snackbar
                v-if="snackbar"
                :mostrarSnackbar="snackbar"
                :timeoutSnackbar="4000"
                :corSnackbar="snackbarColor"
                :mensagemSnackbar="mensagem"
                @fecharSnackbar="snackbar = false"
            />           
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Criar Apresentante</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-8 py-4">
                    <CriarApresentante
                        :apresentante="apresentante"
                        :listaTiposApresentante="listaTiposApresentante"
                        @setIsInvalid="isInvalid = $event"
                        @criarApresentante="verificaApresentante"
                        @fecharModal="closeDialog"
                    />
                </v-card-text>
                <v-divider />
                <v-card-actions
                    style="display: flex; justify-content: end; padding: 8px 48px 8px 16px"
                >
                    <v-btn
                        class="ml-5"
                        color="green white--text"
                        data-cy="salvarApresentante"
                        :disabled="isInvalid"
                        width="120"
                        @click="verificaApresentante"
                    >
                        Salvar
                    </v-btn>
                    <v-btn
                        class="ml-5"
                        dense
                        color="error"
                        width="120"
                        @click="closeDialog"
                    >
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- fim da tela criar Apresentante --------------------- -->

        <!-- Tela para editar Apresentante -->
        <v-dialog
            v-if="dialogEditar"
            v-model="dialogEditar"
            max-width="1200px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="closeDialogEditar">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Editar Apresentante</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-8 py-4">
                    <EditarApresentante
                        :apresentante="apresentante"
                        :apresentante-entidade="apresentanteEntidade"
                        :ativo-protestado="ativoProtestado"
                        :loading="loading"
                        :listaTiposApresentante="listaTiposApresentante"
                        @setIsInvalid="isInvalid = $event"
                        @salvarApresentante="alterarApresentante"
                        @fecharModal="closeDialogEditar"
                        @loadingFalse="loadingFalse"
                        @atualizarApresentante="apresentante = $event"
                    />
                </v-card-text>
                <v-divider />
                <v-card-actions
                    style="display: flex; justify-content: end; padding: 8px 48px 8px 16px"
                >
                    <v-btn
                        class="ml-5"
                        color="green white--text"
                        data-cy="alterarApresentante"
                        :disabled="isInvalid"
                        width="120"
                        @click="alterarApresentante"
                    >
                        Salvar
                    </v-btn>
                    <v-btn
                        class="ml-5"
                        dense
                        color="error"
                        width="120"
                        @click="closeDialogEditar"
                    >
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- fim da tela para editar Apresentante --------------------- -->
        <!-- Tela para criar apresentante experience -->
        <v-dialog
            v-model="dialogCriarApresentanteExperience"
            max-width="800px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="dialogCriarApresentanteExperience = false, feedbackExperience= ''">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Criar Apresentante Experience</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-divider />
                <v-card-text v-if="loadingCriarApresentanteExperience" class="px-8 py-4">
                    <v-row>
                        <v-col class="d-flex justify-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                ></v-progress-circular>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-center">
                            Enviando...
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-else-if="!loadingCriarApresentanteExperience && feedbackExperience" class="px-8 py-4">
                    <v-row>
                        <v-col>
                            <span>{{feedbackExperience}}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-else class="px-8 py-4">
                    <v-row>
                        <v-col>
                            <span>Você gostaria de criar um apresentante Experience BVS ou Recuperi?</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn 
                                class="float-right"
                                color="primary"
                                data-cy="criarApresentanteExperienceBVS"
                                @click="CriarApresentanteExperience('bvs')"
                            >
                                BVS
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="primary"
                                data-cy="criarApresentanteExperienceRecuperi"
                                @click="CriarApresentanteExperience('recuperi')"
                            >
                                Recuperi
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>                
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import ApresentanteService from '@/services/apresentanteService';
import Apresentante from '@/domains/Apresentantes';
import CriarApresentante from '@/components/CriarApresentante';
import EditarApresentante from '@/components/EditarApresentante';
import { getLettersAndNumbers, validarCNPJ } from '@/helpers/Utilitarios';
import Loading from 'vue-loading-overlay';
import lodash from 'lodash';
import EmailService from '@/services/EmailService';
export default {
    name: 'ListaUsuarios',
    mixins: [validationMixin],
    components: {
        CriarApresentante,
        EditarApresentante,
        Loading
    },
    validations: {
        checkbox: {
            checked(val) {
                return val;
            }
        }
    },

    data() {
        return {
            subDomain: null,
            dialogCriarApresentanteExperience: false,
            loadingCriarApresentanteExperience: false,
            feedbackExperience: null,
            apresentanteService: new ApresentanteService(Vue.http, this.$store),
            filtroApresentante: '',
            apresentantes: [],
            filtros: {
                id: null,
                cartao: false,
                boleto: false,
                status: false,
                data_inicio: this.computedDateFormattedInicio,
                data_fim: this.computedDateFormattedFim,
                data_inicio_fmt: null,
                data_fim_fmt: null,
                valor_inicial: null,
                valor_final: null
            },
            salvandoApresentante: false,
            isInvalid: false,
            dialogLoading: false,
            loading: false,
            dialog: false,
            dialogEditar: false,
            loadingFiltros: null,
            data_inicio: null,
            data_fim: null,
            passwordConfirmation: null,
            menu1: false,
            menu2: false,
            modal: false,
            modal2: false,
            cartao: true,
            boleto: true,
            status: true,
            id: null,
            ultimoFiltro: null,
            pagina: 1,
            showPagination: false,
            apresentante: new Apresentante(),
            apresentanteEntidade: null,
            ativoProtestado: false,
            snackbar: false,
            primaryColorFont: '#757575',
            snackbarColor: 'green white--text',
            mensagem: null,
            mostrarAtivosComando: true,
            mostrarAtivosRemessa: true,
            filterSelected: {},
            filtrosSelect:[
                {
                    name: 'Listar todos os apresentantes', 
                    value: {}
                },
                {
                    name: 'Exibir apenas os com comando ativo', 
                    value: {st_ativo_comando: true}
                },
                {
                    name: 'Exibir apenas os com remessa ativa', 
                    value: {st_ativo_remessa: true}
                },
                {
                    name: 'Exibir apenas os com comando inativo', 
                    value: {st_ativo_comando: false}
                },
                {
                    name: 'Exibir apenas os com remessa inativo', 
                    value: {st_ativo_remessa: false}
                },
                {
                    name: 'Exibir apenas os com negociação ativa', 
                    value: {st_ativo_negociacao: true}
                },
                {
                    name: 'Exibir apenas os com negociação inativa', 
                    value: {st_ativo_remessa: false}
                },
                {
                    name: 'Exibir os apresentantes com comando e remessa inativos', 
                    value: {st_ativo_remessa: false, st_ativo_comando: false}
                }
            ],
            headers: [
                {
                    text: 'Código',
                    sortable: false,
                    value: 'cd_apresentante'
                },
                {
                    text: 'Razão Social',
                    sortable: false,
                    value: 'nm_apresentante'
                },
                {
                    text: 'Data de liberação Estados',
                    sortable: false,
                    value: 'dt_liberacao_estados'
                },
                {
                    text: 'Status',
                    sortable: false,
                    value: 'st_ativo'
                },
                {
                    text: 'Nome de Exibição',
                    sortable: false,
                    value: 'nome_exibicao'
                },
                {
                    text: 'Link de Contato',
                    sortable: false,
                    value: 'link_contato'
                },
                {
                    text: 'Ativo Comando',
                    sortable: false,
                    align: 'center',
                    value: 'st_ativo_comando'
                },
                {
                    text: 'Ativo Remessa',
                    sortable: false,
                    align: 'center',
                    value: 'st_ativo_remessa'
                },
                {
                    text: 'Ativo Negociação',
                    sortable: false,
                    align: 'center',
                    value: 'st_ativo_negociacao'
                },
                {
                    text: 'Mensageria',
                    sortable: false,
                    align: 'center',
                    value: 'st_mensageria'
                },
                {
                    text: 'Notifica',
                    sortable: false,
                    align: 'center',
                    value: 'notifica_company_id'
                },
                {
                    text: 'Protestado',
                    sortable: false,
                    align: 'center',
                    value: 'st_protestado'
                }
            ]
        };
    },

    methods: {
        getLettersAndNumbers,
        validarCNPJ,
        CriarApresentanteExperience(tipo) {
            this.loadingCriarApresentanteExperience = true;
            this.apresentanteService.criarApresentanteExperience(tipo).then(
                response => {
                    this.feedbackExperience = response.data.mensagem;
                    this.loadingCriarApresentanteExperience = false;
                    this.mensagem = 'Apresentante Experience criado com sucesso!';
                    this.snackbar = true;
                    this.snackbarColor = 'green white--text';
                    this.listarApresentantes();
                },
                error => {
                    console.log(error);
                    // this.salvandoApresentante = false;
                    this.mensagem = 'Erro Ao Criar Apresentante Experience, dados inconsistentes';
                    this.snackbar = true;
                    this.snackbarColor = 'red white--text';
                }
            );
        },
        exibirDetalhe(apresentante) {
            this.loading = true;
            this.apresentante = new Apresentante();
            let apresentanteService = new ApresentanteService(
                Vue.http,
                this.$store
            );
            let p_apresentante;
            apresentanteService.detalhesApresentante(apresentante).then(
                response => {
                    this.apresentante = lodash.cloneDeep(response.body);
                    this.apresentante.nu_cnpj = String(
                        this.apresentante.nu_cnpj
                    );
                    p_apresentante = this.apresentante.cd_apresentante;
                    apresentanteService
                        .consultaApresentanteProtestado(p_apresentante)
                        .then(
                            response => {
                                this.apresentanteEntidade = lodash.cloneDeep(
                                    response.body
                                );
                                this.dialogEditar = true;
                                this.loading = false;
                                if (this.apresentanteEntidade.id) {
                                    this.ativoProtestado = true;
                                    this.$emit('loadingFalse');
                                }
                            },
                            error => {
                                console.error(error)
                                this.mensagem = 'Erro ao buscar dados do apresentante na Protestado';
                                this.dialogLoading = false;
                                this.dialogEditar = true;
                                this.ativoProtestado = false;
                                this.loading = false;
                                this.$emit('loadingFalse');
                            }
                        );
                },
                error => {
                    this.mensagem = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        },
        verificaApresentante() {
            this.salvarApresentante();
        },
        salvarApresentante: function() {
            let Apresentante = new ApresentanteService(Vue.http, this.$store);
            this.salvandoApresentante = true;
            let data = lodash.cloneDeep(this.apresentante);
            data.cra_nacional = data.cra_nacional ? 1 : 0;
            data.st_ativo = data.st_ativo ? 1 : 0;
            data.st_ativo_remessa = 1;
            data.tx_basic_auth_user = data.cra_user
            data.tx_basic_auth_pass = data.cra_passwd
            delete data.cra_user
            delete data.cra_passwd
            const cnpjValido = validarCNPJ(data.nu_cnpj);

            if (data.id_tipo_apresentante == null) {
                delete data.id_tipo_apresentante;
            }

            if (cnpjValido) {
                let cnpj = this.getLettersAndNumbers(data.nu_cnpj);
                if (
                    cnpj &&
                    cnpj.length === 14 &&
                    data.cd_apresentante &&
                    data.cd_apresentante.length === 3
                ) {
                    data.nu_cnpj = cnpj;
                    Apresentante.criarNovoApresentante(data).then(
                        response => {
                            if (response.status == 201) {
                                let emailService = new EmailService(Vue.http, this.$store);
                                emailService.criaLembretes(this.apresentante.dias_para_envio || 7, data.cd_apresentante)
                                this.mensagem =
                                    'Apresentante Criado com sucesso';
                                this.tipoMensagem = 'success';
                                this.dialog = false;
                                this.dialogEditar = false;
                                this.isInvalid = false;
                                this.snackbar = true;
                                this.snackbarColor = 'green white--text';
                            }
                            if (response.status == 400) {
                                this.tipoMensagem = 'error';
                                this.mensagem =
                                    'Erro Ao Criar Apresentante, dados inconsistentes';
                                this.snackbar = true;
                                this.snackbarColor = 'red white--text';
                            }
                            this.salvandoApresentante = false;
                            this.listarApresentantes();
                        },
                        error => {
                            console.log(error);
                            this.salvandoApresentante = false;
                            this.mensagem =
                                'Erro Ao Criar Apresentante, dados inconsistentes';
                            this.snackbar = true;
                            this.snackbarColor = 'red white--text';
                        }
                    );
                } else {
                    this.salvandoApresentante = false;
                    this.mensagem =
                        'Erro ao criar Apresentante, favor, verificar os campos de CNPJ e Código do Apresentante';
                    this.snackbar = true;
                    this.snackbarColor = 'red white--text';
                }
            } else {
                this.salvandoApresentante = false;
                this.mensagem = 'O formato de CNPJ fornecido não é válido.';
                this.snackbar = true;
                this.snackbarColor = 'red white--text';
            }
        },
        alterarApresentante: function() {
            let Apresentante = new ApresentanteService(Vue.http, this.$store);
            this.salvandoApresentante = true;
            let data = lodash.cloneDeep(this.apresentante);
            data.cra_nacional = data.cra_nacional ? 1 : 0;
            data.st_ativo = data.st_ativo ? 1 : 0;
            data.st_ativo_comando = data.st_ativo_comando ? 1 : 0;
            data.st_ativo_remessa = data.st_ativo_remessa ? 1 : 0;
            data.st_ativo_negociacao = data.st_ativo_negociacao ? 1 : 0;
            delete data.tp_apresentante;
            const cnpjValido = validarCNPJ(data.nu_cnpj);
            if (cnpjValido) {
                const cnpj = this.getLettersAndNumbers(data.nu_cnpj);
                if (cnpj && cnpj.length === 14) {
                    data.nu_cnpj = cnpj;
                    Apresentante.editarApresentante(data).then(
                        response => {
                            if (response.status == 200) {
                                this.mensagem =
                                    'Apresentante Editado com Sucesso';
                            }
                            this.tipoMensagem = 'success';
                            this.dialog = false;
                            this.dialogEditar = false;
                            this.salvandoApresentante = false;
                            this.isInvalid = false;
                            this.snackbar = true;
                            this.snackbarColor = 'green white--text';
                            this.listarApresentantes();
                        },
                        error => {
                            console.log('Erro do snackbar',error)
                            this.salvandoApresentante = false;
                            this.mensagem = error.body
                                ? error.body
                                : 'Erro ao executar solicitação.';
                            this.tipoMensagem = 'error';
                            this.snackbarColor = 'red white--text';
                            this.snackbar = true;
                        }
                    );
                } else {
                    this.salvandoApresentante = false;
                    this.mensagem =
                        'Erro ao Editar Apresentante, CNPJ com número insuficiente de caracteres';
                    this.snackbar = true;
                    this.snackbarColor = 'red white--text';
                }
            } else {
                this.salvandoApresentante = false;
                this.mensagem = 'O formato de CNPJ fornecido não é válido.';
                this.snackbar = true;
                this.snackbarColor = 'red white--text';
            }
        },
        criarApresentante() {
            this.apresentante = new Apresentante();
            this.apresentante.cra_nacional = false;
            this.apresentante.st_ativo = true;
            this.dialog = true;
            this.dialogLoading = false;
        },
        listarApresentantes() {
            return new Promise((resolve, reject) => {
                try {
                    this.loading = true;
                    let usuarioService = new ApresentanteService(
                        Vue.http,
                        this.$store
                    );
                    usuarioService.listarApresentantes(this.filterSelected).then(
                        response => {
                            this.loading = false;
                            if (response.status == 200) {
                                this.apresentantes = response.body;
                                this.verificarApresentanteProtestadoStatusMensageria();
                            }
                        },
                        error => {
                            this.loading = false;
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        verificarApresentanteProtestadoStatusMensageria() {
            if (this.apresentantes?.length > 0) {
                for (
                    let index = 0;
                    index < this.apresentantes.length;
                    index++
                ) {
                    const apresentante = this.apresentantes[index];
                    apresentante.st_mensageria = false;
                    apresentante.st_protestado = false;

                    // Verifica se o apresentante está habilitado na Protestado
                    if (
                        this.verificarValorPreenchido(
                            apresentante.protestado_uuid
                        )
                    ) {
                        apresentante.st_protestado = true;
                    }

                    // Verifica se o apresentante está apto para Mensageria
                    if (
                        this.verificarValorPreenchido(
                            apresentante.nome_exibicao
                        ) &&
                        this.verificarValorPreenchido(apresentante.link_contato)
                    ) {
                        apresentante.st_mensageria = true;
                    }
                }
            }
        },
        verificarValorPreenchido(valor) {
            if (valor && valor.trim() !== '') {
                return true;
            }
            return false;
        },
        closeDialog() {
            // this.$refs.usuarioForm.reset();
            this.dialog = false;
            this.isInvalid = false;
        },
        closeDialogEditar() {
            // this.$refs.usuarioForm.reset();
            this.dialogEditar = false;
            this.isInvalid = false;
            this.apresentante = null;
            this.apresentanteEntidade = null;
            this.ativoProtestado = null;
            this.listarApresentantes();
        },
        loadingFalse() {
            this.loading = false;
        },
        getBolean(valor) {
            if (valor) {
                return 'done';
            } else {
                return 'close';
            }
        },
        async buscarTiposApresentante() {
            try {
                this.loading = true;
                const tiposApresentante = await this.apresentanteService.buscarTiposApresentante();
                if (tiposApresentante?.length > 0) {
                    this.$store.commit(
                        'setListaTiposApresentante',
                        tiposApresentante
                    );
                }
            } catch (e) {
                this.mensagem =
                    'Erro ao buscar tipos de apresentante. Tente novamente mais tarde.';
                this.snackbar = true;
                this.snackbarColor = 'red white--text';
            } finally {
                this.loading = false;
            }
        }
    },
    computed: {
        isPartner() {
            return this.$store.getters.isPartner;
        },
        listaTiposApresentante() {
            return this.$store.getters.listaTiposApresentante;
        }
    },
    async mounted() {
        this.subDomain = window.location.hostname.split('.')[0];
        if (!this.listaTiposApresentante?.length > 0) {
            await this.buscarTiposApresentante();
        }
        this.listarApresentantes();
        if (this.isPartner == 1) {
            this.primaryColorFont = '#757575';
        } else {
            this.primaryColorFont = this.$vuetify.theme.themes.light.primary;
            this.headers.push({ text: ' ', sortable: false, value: 'action' });
        }
    }
};
</script>
